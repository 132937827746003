import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton, Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiContext
} from '@mui/x-data-grid';
import { useState } from 'react';
import SvgIconStyle from '../../components/SvgIconStyle';
import { CreateNewCourseModal } from './newCourseModal';

export function Toolbar({ incrementPageTotal, refresh }) {
  const apiRef = useGridApiContext();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  // const [dataToExport, setDataToExport] = useState([]);
  // const [loadingExport, setLoadingExport] = useState(false);

  // const handleExport = (_, done) => {
  //   setLoadingExport(true);
  //   fetchDataToExport().then((res) => {
  //     if (res.status === 200) {
  //       setDataToExport(res.data.data);
  //       setLoadingExport(false);
  //       done(true);
  //       // return res.data.data;
  //     }
  //   });
  // };

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          '.MuiButton-root': {
            fontSize: '0.8125rem'
          }
        }}>
        <Stack direction="row">
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <IconButton size="small" color="primary" onClick={refresh}>
            <RefreshIcon />
          </IconButton>

          {/* <Button startIcon={<SaveAltIcon />}>
            <CSVLink
              style={{
                color: 'inherit',
                textDecoration: 'none'
              }}
              filename="cursos"
              asyncOnClick
              onClick={handleExport}
              data={dataToExport}>
              Export{' '}
              {loadingExport && (
                <CircularProgress size="0.75em" thickness={7} disableShrink color="primary" />
              )}
            </CSVLink>
          </Button> */}
        </Stack>

        <Stack direction="row">
          <Button
            onClick={() => setCreateModalOpen(true)}
            startIcon={<SvgIconStyle src="/static/icons/ic_plus.svg" />}>
            Criar curso
          </Button>
        </Stack>
      </GridToolbarContainer>

      <CreateNewCourseModal
        open={createModalOpen}
        close={() => setCreateModalOpen(false)}
        updateDataGrid={apiRef.current.updateRows}
        incrementPageTotal={incrementPageTotal}
      />
    </>
  );
}

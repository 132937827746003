// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';

// ----------------------------------------------------------------------

export default function Modal({
  open,
  close,
  title,
  closeButtonText,
  submitButtonText,
  submitButtonAction,
  disableButton,
  children
}) {
  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {closeButtonText ?? 'Cancelar'}
        </Button>
        <Button onClick={submitButtonAction} disabled={disableButton} variant="contained">
          {submitButtonText ?? 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerifiedIcon from '@mui/icons-material/VerifiedRounded';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import SvgIconStyle from '../../components/SvgIconStyle';
import { useAuth } from '../../hooks/useAuth';
import { useObjectState } from '../../hooks/useObjectState';
import { useToast } from '../../hooks/useToast';
import { HigherEducationsService } from '../../services/HigherEducations';
import AddUpdateModal from './addUpdateModal';
import AnalysisModal from './analysisModal';

export default function CoursesMoreMenu({ data, updateDataGrid }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, updateModal] = useObjectState({ editInstitution: false, analysisModal: false });
  const ref = useRef(null);
  const toast = useToast();
  const { authorization } = useAuth();
  const apiRef = useGridApiContext();

  async function toggleActive() {
    setLoading(true);
    const reqResponse = await HigherEducationsService.updateUniversities({
      id: data.id,
      status: !data.status
    });
    if (reqResponse.isError) {
      setLoading(false);
      return toast.error(reqResponse.data.message);
    }
    setLoading(false);
    toast.success(
      `Instituição "${data.institution}" ${
        reqResponse.data.data.status ? 'ativada' : 'desativada'
      }!`
    );
    return apiRef.current.updateRows([reqResponse.data.data]);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>
      <AddUpdateModal
        open={modal.editInstitution}
        setOpen={() => updateModal('editInstitution', false)}
        data={data}
        editMode
      />
      <AnalysisModal
        open={modal.analysisModal}
        setOpen={() => updateModal('analysisModal', false)}
        data={data}
        updateDataGrid={updateDataGrid}
      />

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {!data.analyzed
          ? [
              <MenuItem
                key={0}
                onClick={() => {
                  updateModal('analysisModal', true);
                  setIsOpen(false);
                }}
                disabled={!authorization.authority.isAdmin}>
                <ListItemIcon>
                  <VerifiedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Analisar informação"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            ]
          : [
              <MenuItem
                key={1}
                onClick={() => {
                  updateModal('editInstitution', true);
                  setIsOpen(false);
                }}
                disabled={!authorization.authority.isAdmin}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>,

              <MenuItem
                key={2}
                onClick={() => toggleActive()}
                disabled={!authorization.authority.isAdmin}
                sx={{ color: `${data.status ? 'error.main' : 'success.main'}` }}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress
                      size="0.75em"
                      thickness={7}
                      disableShrink
                      color={!data.status ? 'success' : 'error'}
                      sx={{ ml: 0.75 }}
                    />
                  ) : (
                    <SvgIconStyle src={`/static/icons/ic_${!data.status ? 'plus' : 'minus'}.svg`} />
                  )}
                </ListItemIcon>
                <ListItemText>{!data.status ? 'Ativar' : 'Desativar'} </ListItemText>
              </MenuItem>
            ]}
      </Menu>
    </>
  );
}

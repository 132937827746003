import FilterListIcon from '@mui/icons-material/FilterList';
import GetApp from '@mui/icons-material/GetApp';
import {
  Card,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useState } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import Filter from '../components/Filter';
import { Loading } from '../components/loading';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { useUserList } from '../hooks/useUserList';
import { UsersService } from '../services/users';

export default function Users() {
  const [isOpen, setIsOpen] = useState(false);
  const { data, getUserList, page, savePage, loading, saveLoading, filters } = useUserList();
  const handleChangePage = async (event, newPage) => {
    savePage(newPage);
  };

  async function handleDownloadCsv() {
    const fileName = new Date().toLocaleDateString('pt-BR', { dateStyle: 'short' });
    await UsersService.getCsv({ ...filters, containSensitiveData: 1 }).then((res) => {
      const data = res.data.split('\n');
      const header = data[2].split(',');
      const body = data.slice(3).map((item) => item.split(','));
      downloadExcel({
        fileName,
        sheet: 'Usuários',
        tablePayload: {
          header,
          body
        }
      });
    });
  }

  return (
    <Page title="Usuários | Preparo">
      <Filter
        saveLoading={saveLoading}
        getUserList={getUserList}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => setIsOpen(false)}
      />

      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Usuários
            </Typography>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip
                      onClick={async () => {
                        handleDownloadCsv();
                      }}
                      title="Exportar XLS">
                      <IconButton>
                        <GetApp />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip onClick={() => setIsOpen(true)} title="Lista de filtros">
                      <IconButton>
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Sobrenome</TableCell>
                      <TableCell>Telefone</TableCell>
                      <TableCell>Data de Criação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.length ? (
                      data.data.map((user) => (
                        <TableRow
                          key={`${user.firstName}${user._id}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {user.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {user.firstName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {user.lastName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {user.phoneNumber}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(user.createdAt).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Typography variant="subtitle1" align="center" sx={{ p: 3 }}>
                        Nenhum resultado.
                      </Typography>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>{' '}
              <TablePagination
                rowsPerPageOptions={[25]}
                component="div"
                onPageChange={handleChangePage}
                count={data.total}
                rowsPerPage={25}
                page={page}
              />
            </Scrollbar>
          </Card>
        </Container>
      )}
    </Page>
  );
}

const columns = [
  { field: '_id', headerName: 'ID', width: 150, hide: true },
  {
    field: 'email',
    headerName: 'Email',
    width: 150,
    renderCell: ({ value }) => <Typography title={value}>{value}</Typography>
  },
  { field: 'firstName', headerName: 'Nome', width: 150 },
  { field: 'lastName', headerName: 'Sobrenome', width: 150 },
  { field: 'phoneNumber', headerName: 'Telefone', width: 150 },
  { field: 'lastLogin', headerName: 'Último Login', width: 150 },
  { field: 'createdAt', headerName: 'Data de Criação', width: 150 }
];

import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';

export function Select({ options, label, checkboxes = false, ...rest }) {
  return (
    <Autocomplete
      multiple={checkboxes}
      options={options}
      disableCloseOnSelect={checkboxes}
      getOptionLabel={(option) => option.label}
      renderOption={
        checkboxes
          ? (props, option, { selected }) => (
              <li style={{ padding: '0.25rem 0' }} {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                <Typography variant="body2">{option.label}</Typography>
              </li>
            )
          : undefined
      }
      renderInput={(params) => <TextField {...params} label={label} />}
      isOptionEqualToValue={(op, value) => op.value === value.value}
      {...rest}
    />
  );
}

import { api } from '..';
import { PROCESS } from '../routes';

export class ProcessService {
  /**
   * @param {{ page:number, limit:number, orderBy: string, ascending: boolean }} params
   * @returns
   */
  static async getPaginated(params) {
    const res = await api.get(PROCESS.PAGINATED, { params });
    return res;
  }

  static async getProcessByCompanies(params) {
    const res = await api.get('processes/getProcessByCompanies', { params });
    return res;
  }
}

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { EditStaffMemberModal } from '.';
import { useAuth } from '../../../hooks/useAuth';
import { useObjectState } from '../../../hooks/useObjectState';
import { useToast } from '../../../hooks/useToast';
import { managementService } from '../../../services/management';

export default function StaffMoreMenu({ staffMember, refresh = () => {} }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const [modal, updateModal] = useObjectState();
  const { authorization } = useAuth();

  const disableAccess = useCallback(async () => {
    const res = await managementService.deactivateStaffMember({ _id: staffMember._id });
    if (res.isError) {
      return toast.error(res.data.message);
    }
    toast.success(`Acesso desabilitado para "${staffMember.user.name}"`);
    refresh();
  }, [staffMember]);

  const enableAccess = useCallback(async () => {
    const res = await managementService.activateStaffMember({ _id: staffMember._id });
    if (res.isError) {
      return toast.error(res.data.message);
    }
    toast.success(`Acesso habilitado para "${staffMember.user.name}"`);
    refresh();
  }, [staffMember]);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      {modal.editStaffMember && (
        <EditStaffMemberModal
          staffMember={staffMember}
          open={modal.editStaffMember}
          onSuccess={refresh}
          handleClose={() => {
            updateModal('editStaffMember', false);
          }}
        />
      )}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem
          onClick={() => {
            updateModal('editStaffMember', true);
            setIsOpen(false);
          }}
          disabled={staffMember._id === authorization._id || !authorization.authority.isAdmin}
          sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={enableAccess}
          disabled={staffMember._id === authorization._id || !authorization.authority.isAdmin}
          sx={{ color: 'text.secondary' }}
          divider>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Habilitar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={disableAccess}
          disabled={staffMember._id === authorization._id || !authorization.authority.isAdmin}
          sx={{ color: 'primary.main' }}>
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText primary="Desabilitar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

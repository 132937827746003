import { Navigate, useRoutes } from 'react-router-dom';
import { UserListProvider } from './contexts/UserListContext';
import { useAuth } from './hooks/useAuth';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import DashboardLayout from './layouts/dashboard';
import Blog from './pages/Blog';
import Company from './pages/Company';
import Courses from './pages/Courses';
import DashboardApp from './pages/DashboardApp';
import Institutions from './pages/Institutions';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import AllPartners from './pages/Partners';
import CreatePartner from './pages/Partners/create';
import Process from './pages/Process';
import Staff from './pages/Staff';
import User from './pages/User';

function PrivateRoute({ children }) {
  const { isSignedIn } = useAuth();
  if (isSignedIn) {
    return children;
  }
  return <Navigate to="/login" />;
}

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'staff', element: <Staff /> },
        {
          path: 'users',
          element: (
            <UserListProvider>
              <User />
            </UserListProvider>
          )
        },
        { path: 'companies', element: <Company /> },
        { path: 'processes', element: <Process /> },
        { path: 'blog', element: <Blog /> },
        { path: 'courses', element: <Courses /> },
        { path: 'institutions', element: <Institutions /> },
        {
          path: 'agencies',
          children: [
            { path: 'list', element: <AllPartners /> },
            { path: 'create', element: <CreatePartner /> }
          ]
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

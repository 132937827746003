import { useCallback, useRef, useState } from 'react';
import { useCleanupEffect } from './useCleanupEffect';
import { useToast } from './useToast';

import { CoursesService } from '../services/courses';

export function useCourses() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({});
  const [sortingOrder, setSortingOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [loading, setLoading] = useState(true);
  const timeout = useRef(null);
  const toast = useToast();

  const debouncerTimeout = 300;

  useCleanupEffect(
    (state) => {
      CoursesService.getTotalNumberOfCourses(filter).then((res) => {
        if (state.isMounted) setTotal(res.data.data.totalNumber);
      });
    },
    [filter]
  );

  useCleanupEffect(
    (state) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setLoading(true);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        fetchPages({ page, pageSize, filter, orderBy, sortingOrder }).then((res) => {
          if (res.isError) {
            setData([]);
            setTotal(0);
            toast.error('Nenhum resultado encontrado.', {
              autoClose: false,
              hideProgressBar: true,
              progress: undefined,
              closeButton: true
            });
          } else {
            const { data } = res.data;
            if (state.isMounted) {
              setData(data);
              setLoading(false);
            }
          }
        });
      }, debouncerTimeout);
    },
    [page, pageSize, filter, orderBy, sortingOrder]
  );

  const fetchPages = useCallback(async ({ page, pageSize, filter, orderBy, sortingOrder }) => {
    const res = await CoursesService.getCourses({
      page,
      limit: pageSize,
      ...filter,
      orderBy,
      sortingOrder
    });
    return res;
  }, []);

  const refresh = async () => {
    setLoading(true);
    const res = await fetchPages({ page, pageSize, filter, orderBy, sortingOrder });
    setLoading(false);
    return res;
  };

  const fetchAllCoursesFiltered = async () => {
    const res = await CoursesService.getCourses(filter);
    return res;
  };

  const handlePageChange = useCallback((nextPage) => {
    setPage(nextPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const handleFilterChange = useCallback((newFilter) => {
    setFilter(newFilter);
  }, []);

  const incrementPageTotal = () => {
    setTotal(total + 1);
  };

  const handleSortModelChange = useCallback(([sortingModel]) => {
    setOrderBy(sortingModel?.field);
    setSortingOrder(sortingModel?.sort);
  }, []);

  const updateMultiple = (coursesToUpdate, change) => {
    setData(
      data.map((course) => {
        if (coursesToUpdate.includes(course.id)) {
          return {
            ...course,
            ...change
          };
        }
        return course;
      })
    );
  };

  return {
    data,
    loading,
    pagination: {
      page,
      pageSize,
      total,
      handlePageChange,
      handlePageSizeChange
    },
    filter: {
      filter,
      handleFilterChange
    },
    sorting: { sortingOrder, orderBy, handleSortModelChange },
    incrementPageTotal,
    fetchAllCoursesFiltered,
    updateMultiple,
    refresh
  };
}

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

export function DataGridCustom({ columns, data, pagination, sorting = {}, ...rest }) {
  const { page, total, pageSize, handlePageChange, handlePageSizeChange } = pagination;
  const { handleSortModelChange } = sorting;

  return (
    <DataGrid
      // -- data --
      columns={columns}
      rows={data}
      getRowId={(dataItem) => dataItem._id}
      // -- pagination --
      pagination
      page={page}
      pageSize={pageSize}
      rowCount={total}
      onPageSizeChange={handlePageSizeChange}
      onPageChange={handlePageChange}
      paginationMode="server"
      rowsPerPageOptions={[15, 25, 35, 100]}
      // -- sorting --
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      autoHeight
      disableSelectionOnClick
      components={{
        Toolbar: GridToolbar
      }}
      {...rest}
    />
  );
}

import { useEffect, useRef } from 'react';

export function useDebouncerEffect(action = () => {}, dependencies = [], delayMillis = 500) {
  const timeoutHandler = useRef(null);
  useEffect(() => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    timeoutHandler.current = setTimeout(action, delayMillis);
  }, dependencies);
}

import bxsUser from '@iconify/icons-bx/bxs-user';
import peopleFill from '@iconify/icons-eva/people-fill';
import baselineBusinessCenter from '@iconify/icons-ic/baseline-business-center';
import { Container, Grid } from '@mui/material';
import { useState } from 'react';
import Page from '../components/Page';
import {
  AppConversionRates,
  AppCurrentSubject,
  AppCurrentVisits,
  AppNewsUpdate,
  AppOrderTimeline,
  AppTasks,
  AppTrafficBySite,
  AppWebsiteVisits
} from '../components/_dashboard/app';
import AppStatsCard from '../components/_dashboard/app/AppStatsCard';
import { useCleanupEffect } from '../hooks/useCleanupEffect';
import { statsService } from '../services/stats';

export default function DashboardApp() {
  const [stats, setStats] = useState({});

  useCleanupEffect((state) => {
    statsService.getStats().then((res) => {
      if (state.isMounted) {
        setStats(res.data);
      }
    });
  }, []);

  return (
    <Page title="Dashboard | Preparo">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppStatsCard icon={peopleFill} title="Usuários" value={stats.usersAmount} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppStatsCard
              icon={baselineBusinessCenter}
              title="Empresas"
              value={stats.companiesAmount}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppStatsCard icon={bxsUser} title="Processos" value={stats.activeProcesses} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { RHFCombobox, RHFRadioGroup, RHFTextField } from '../../components/hook-form';
import Modal from '../../components/Modal';
import SearchNotFound from '../../components/SearchNotFound';
import { useDebouncerEffect } from '../../hooks/useDebouncerEffect';
import { useToast } from '../../hooks/useToast';
import { CoursesService } from '../../services/courses';

export function AnalysisModal({ open, close, courseData, updateDataGrid }) {
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const toast = useToast();

  const schema = yup.object().shape({
    courseToBeLinked: yup
      .object({
        id: yup.string(),
        course: yup.string()
      })
      .when('shouldLinkToExistingCourse', {
        is: 'shouldLink',
        then: (schema) => schema.required('É obrigatório escolher um curso para ser vinculado.'),
        otherwise: (schema) => schema.nullable()
      }),
    shouldLinkToExistingCourse: yup
      .mixed()
      .oneOf(['shouldLink', 'shouldNotLink'], 'É obrigatório selecionar uma dessas opções.')
      .required('É obrigatório selecionar uma dessas opções.'),
    shouldActivateNewCourse: yup
      .mixed()
      .oneOf(['shouldActivate', 'shouldNotActivate'], 'É obrigatório selecionar uma dessas opções.')
      .required('É obrigatório selecionar uma dessas opções.'),
    newCourseName: yup.string().when('shouldLinkToExistingCourse', {
      is: 'shouldNotLink',
      then: (schema) => schema.required('É obrigatório o nome do novo curso.'),
      otherwise: (schema) => schema
    })
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError
  } = useForm({
    defaultValues: {
      courseToBeLinked: null,
      shouldLinkToExistingCourse: '',
      shouldActivateNewCourse: '',
      newCourseName: courseData.course
    },
    resolver: yupResolver(schema)
  });

  const shouldLinkToExistingCourse = useWatch({
    name: 'shouldLinkToExistingCourse',
    control
  });

  const submit = async (data) => {
    if (data.shouldLinkToExistingCourse === 'shouldLink') {
      const res = await CoursesService.replaceCourse({
        courseToReplaceId: data.courseToBeLinked.id,
        courseToBeDeletedId: courseData.id
      });

      if (res.status === 200) {
        await CoursesService.editCourse({
          id: data.courseToBeLinked.id,
          status: data.shouldActivateNewCourse === 'shouldActivate'
        }).then((res) => {
          if (res.status === 201) {
            updateDataGrid([{ id: courseData.id, _action: 'delete' }]);
            updateDataGrid([{ ...res.data.data }]);
            toast.success(
              `${courseData.course} foi vinculado a ${res.data.data.course} com sucesso!`
            );
            close();
          }
          setError('courseToBeLinked', { message: 'Houve um erro na vinculação desse curso.' });
        });
      } else {
        setError('courseToBeLinked', { message: 'Houve um erro na vinculação desse curso.' });
      }
    } else if (data.shouldLinkToExistingCourse === 'shouldNotLink') {
      await CoursesService.editCourse({
        id: courseData.id,
        course: data.newCourseName,
        status: data.shouldActivateNewCourse === 'shouldActivate',
        analyzed: true
      }).then((res) => {
        if (res.status === 201) {
          updateDataGrid([res.data.data]);
          toast.success(`O curso "${data.newCourseName}" foi criado com sucesso!`);
          close();
        }
        setError('newCourseName', {
          message:
            'Houve um erro na criação desse curso. Verifique se já não existe um curso com o mesmo nome.'
        });
      });
    }
  };

  useDebouncerEffect(
    async () => {
      let isMounted = true;
      if (searchQuery) {
        await CoursesService.getCourses({
          course: searchQuery,
          status: true,
          analyzed: true
        }).then((res) => {
          if (isMounted) {
            if (res.status === 200) {
              setSearchResults(res.data.data);
            } else {
              setSearchResults([]);
            }
          }
        });
      }
      return () => (isMounted = false);
    },
    [searchQuery],
    500
  );

  return (
    <Modal
      open={open}
      close={close}
      closeButtonText="Cancelar"
      submitButtonText={
        <>
          Salvar análise{' '}
          {isSubmitting && (
            <CircularProgress
              size="0.75em"
              thickness={7}
              disableShrink
              sx={{ ml: 0.75, color: 'inherit' }}
            />
          )}
        </>
      }
      submitButtonAction={handleSubmit(submit, () => {})}
      title="Análise de registro">
      <Stack
        gap={3}
        sx={{
          '.MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: 'grey.5008'
          }
        }}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Registro do usuário
          </Typography>
          <TextField disabled size="small" value={courseData.course} fullWidth />
        </Box>

        <RHFRadioGroup
          name="shouldLinkToExistingCourse"
          control={control}
          options={['shouldLink', 'shouldNotLink']}
          getOptionLabel={[
            'Vincular com um dado já existente',
            'Não há nenhum dado equivalente registrado'
          ]}
        />

        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Vincular com algum documento equivalente
          </Typography>

          <RHFCombobox
            control={control}
            name="courseToBeLinked"
            label="Buscar entre os registros já feitos"
            options={searchResults}
            onInputChange={(_, newValue) => setSearchQuery(newValue)}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.course)}
            disabled={shouldLinkToExistingCourse !== 'shouldLink'}
            noOptionsText={
              searchResults.length === 0 ? '...' : <SearchNotFound searchQuery={searchQuery} />
            }
          />
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Criar novo registro
          </Typography>

          <RHFTextField
            name="newCourseName"
            control={control}
            label="Nome do curso"
            disabled={shouldLinkToExistingCourse !== 'shouldNotLink'}
          />
        </Box>

        <RHFRadioGroup
          name="shouldActivateNewCourse"
          control={control}
          options={['shouldActivate', 'shouldNotActivate']}
          getOptionLabel={['Ativar', 'O dado informado não será ativado']}
          row={false}
        />
      </Stack>
    </Modal>
  );
}

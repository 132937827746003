// import { useEffect } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, IconButton, Stack, Typography } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { RHFSelect, RHFTextField } from '../../../components/hook-form';

export function AccessForm({ methods }) {
  const {
    formState: { isSubmitting },
    control
  } = methods;

  const { remove, append, fields } = useFieldArray({
    control,
    name: 'accesses'
  });

  return (
    <Card sx={{ p: 3 }}>
      <Stack gap={2}>
        <Typography variant="h6">Acesso à plataforma</Typography>

        {fields.map((field, index) => (
          <Stack
            key={field.id}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-end', sm: 'center' }}
            gap={3}
            sx={{
              border: `solid 1px`,
              borderColor: 'grey.50012',
              borderRadius: 2,
              p: 3
            }}>
            <RHFSelect
              control={control}
              name={`accesses.${index}.permission`}
              disabled={isSubmitting}
              label="Permissão"
              options={[
                { label: 'Administrador', value: 'admin' },
                { label: 'Editor', value: 'editor' },
                { label: 'Visualizador', value: 'viewer' }
              ]}
            />

            <RHFTextField
              control={control}
              name={`accesses.${index}.email`}
              disabled={isSubmitting}
              label="E-mail"
            />

            <IconButton disabled={isSubmitting} onClick={() => remove(index)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        ))}

        <Button
          variant="outlined"
          sx={{ alignSelf: 'flex-end' }}
          onClick={() => append({ email: '', permission: '' })}
          disabled={isSubmitting}
          startIcon={<AddRoundedIcon />}>
          Adicionar novo acesso
        </Button>
      </Stack>
    </Card>
  );
}

import { LoadingButton } from '@mui/lab';
import { Card, Modal, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { capitalCase } from 'change-case';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../hooks/useAuth';
import { useObjectState } from '../../../hooks/useObjectState';
import { useToast } from '../../../hooks/useToast';
import { managementService } from '../../../services/management';
import Label from '../../Label';

NewStaffMemberModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default function NewStaffMemberModal(props) {
  const [tags, updateTags] = useObjectState({});
  const { authority } = useAuth();
  const toast = useToast();

  const NewUserSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: NewUserSchema,
    onSubmit
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  async function onSubmit(formData) {
    const { email } = formData;
    const res = await managementService.addNewStaffMember({ email, authority: tags });
    if (res.isError) {
      toast.error(res.data.message);
    }
    return false;
  }

  function handleSelectLabel(tagName) {
    return () => {
      updateTags(tagName, !tags[tagName]);
    };
  }

  function formatTagName(tagName) {
    return tagName.replace(/is/, '');
  }

  const TAG_LIST = useMemo(
    () => [
      'isAdmin',
      'isDeveloper',
      'isContentManager',
      'isOperationsManager',
      'isFinancialManager'
    ],
    [authority]
  );

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card sx={{ width: 'fit-content', outline: 'none', padding: '2rem', maxWidth: '500px' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Adicionar novo membro ao time
        </Typography>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            noValidate
            onSubmit={handleSubmit}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              sx={{ mt: 2 }}
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <Stack mt={2}>
              <Typography mt={1}>Permissões disponíveis</Typography>
              <Box my={1} display="flex" gap={1} flexWrap="wrap">
                {TAG_LIST.map((tagName) => (
                  <Label
                    key={`label_${tagName}`}
                    sx={{ cursor: 'pointer', userSelect: false, transition: '100ms' }}
                    onClick={handleSelectLabel(tagName)}
                    color={tags[tagName] ? 'primary' : 'default'}>
                    {capitalCase(formatTagName(tagName))}
                  </Label>
                ))}
              </Box>
            </Stack>

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ margin: '0.5rem' }}
              loading={isSubmitting}>
              Adicionar membro
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Card>
    </Modal>
  );
}

import { LoadingButton } from '@mui/lab';
import { Card, Container, Stack } from '@mui/material';
import { useState } from 'react';
import { DataGridCustom } from '../../components/DataGridCustom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SvgIconStyle from '../../components/SvgIconStyle';
import { useCourses } from '../../hooks/useCourses';
import { useToast } from '../../hooks/useToast';
import { CoursesService } from '../../services/courses';
import { columns } from './columns';
import { Toolbar } from './toolbar';

export default function Courses() {
  const [selectionModel, setSelectionModel] = useState([]);
  const toast = useToast();

  const [selectionBtnsLoading, setSelectionBtnsLoading] = useState({
    activate: false,
    deactivate: false
  });

  const {
    data,
    pagination,
    loading,
    filter: { handleFilterChange },
    sorting,
    updateMultiple,
    incrementPageTotal,
    refresh
  } = useCourses();

  const handleFilter = ({ items }) => {
    const filters = items[0];

    handleFilterChange({
      [filters.columnField]: filters.value
    });
  };

  const selectionAction = async ({ newStatus }) => {
    setSelectionBtnsLoading({ activate: newStatus, deactivate: !newStatus });

    const coursesToChange = data.filter(
      (course) =>
        selectionModel.includes(course.id) && course.status === !newStatus && course.analyzed
    );

    if (coursesToChange.length === 0) {
      toast.warn(
        `Nenhum dos cursos selecionados pode ser ${newStatus ? 'ativado' : 'desativado'}.`
      );
      setSelectionBtnsLoading({ activate: false, deactivate: false });
      return;
    }

    const res = await CoursesService.updateMany({
      ids: coursesToChange.map((course) => course.id),
      status: newStatus
    });

    if (res.status === 200) {
      updateMultiple(selectionModel, { status: newStatus });
      toast.success(`${coursesToChange.length} cursos ${newStatus ? 'ativados!' : 'desativados!'}`);
    }

    setSelectionBtnsLoading({ activate: false, deactivate: false });
  };

  return (
    <Page title="Cursos">
      <Container>
        <HeaderBreadcrumbs
          heading="Cursos"
          links={[
            { name: 'Início', href: '/dashboard/app' },
            { name: 'Usuários', href: '/dashboard/users' },
            { name: 'Cursos' }
          ]}
        />

        {selectionModel.length > 0 && (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" mb={2} gap={1}>
            <LoadingButton
              variant="contained"
              loading={selectionBtnsLoading.activate}
              color="success"
              startIcon={<SvgIconStyle src="/static/icons/ic_plus.svg" />}
              onClick={() => selectionAction({ newStatus: true })}>
              Ativar
            </LoadingButton>

            <LoadingButton
              variant="contained"
              color="warning"
              loading={selectionBtnsLoading.deactivate}
              startIcon={<SvgIconStyle src="/static/icons/ic_minus.svg" />}
              onClick={() => selectionAction({ newStatus: false })}>
              Desativar
            </LoadingButton>
          </Stack>
        )}

        <Card>
          <Scrollbar>
            <DataGridCustom
              data={data}
              columns={columns}
              pagination={pagination}
              sorting={sorting}
              loading={loading}
              getRowId={(item) => item.id}
              checkboxSelection
              filterMode="server"
              onFilterModelChange={handleFilter}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              components={{
                Toolbar: () => Toolbar({ incrementPageTotal, refresh })
              }}
            />
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { capitalCase, sentenceCase } from 'change-case';
import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Label from '../components/Label';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { NewStaffMemberModal, StaffListHead, StaffMoreMenu } from '../components/_dashboard/staff';
import StaffMembersListToolbar from '../components/_dashboard/staff/StaffMembersListToolbar';
import { useGetRequest } from '../hooks/useGetRequest';
import { useObjectState } from '../hooks/useObjectState';
import { managementService } from '../services/management';
import { profilePictureURL } from '../utils/helpers';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Telefone', alignRight: false },
  { id: 'permissions', label: 'Permissões', alignRight: false },
  { id: 'status', label: 'Status da conta', alignRight: false },
  { id: '' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Staff() {
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [modal, updateModal] = useObjectState();
  const [staffMembers, isLoading, refresh] = useGetRequest({
    serviceMethod: managementService.listStaffMembers,
    defaultValue: [],
    transformFunction
  });

  function transformFunction(data) {
    let { staffMembers } = data;
    staffMembers = staffMembers.map((sm) => ({
      ...sm,
      user: { ...sm.user, name: `${sm.user?.firstName} ${sm.user?.lastName}` }
    }));
    return staffMembers;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    staffMembers.map((sm) => sm.user),
    getComparator(order, orderBy),
    filterName
  );

  function transformRole(role) {
    return sentenceCase(role.replace(/is/g, ''));
  }

  const staffMembersListIsEmpty = filteredUsers.length === 0;

  return (
    <Page title="Staff | Preparo">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Membros da Preparo
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={() => updateModal('newStaffMember', true)}
            startIcon={<Icon icon={plusFill} />}>
            Adicionar novo membro
          </Button>
        </Stack>

        <Card>
          <StaffMembersListToolbar
            refresh={refresh}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StaffListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={staffMembers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                {!isLoading && (
                  <TableBody>
                    {staffMembers.map((staffMember) => {
                      const { _id, user, isActive, authority } = staffMember;
                      const isItemSelected = selected.indexOf(user.name) !== -1;

                      const photoURL = profilePictureURL(user);
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ paddingLeft: '1rem' }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={user.name} src={photoURL} />
                              <Typography variant="subtitle2" noWrap>
                                {user.name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{user.email}</TableCell>
                          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                            {user.phoneNumber}
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: '20rem' }}>
                            {Object.entries(authority)
                              .filter(([role, isAllowed]) => isAllowed)
                              .map(([role]) => (
                                <Label
                                  key={`role_${role}`}
                                  variant="ghost"
                                  sx={{ marginRight: '0.25rem' }}
                                  color="default">
                                  {capitalCase(transformRole(role))}
                                </Label>
                              ))}
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                              {sentenceCase(isActive ? 'ativada' : 'desativada')}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <StaffMoreMenu staffMember={staffMember} refresh={refresh} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
                {modal.newStaffMember && (
                  <NewStaffMemberModal
                    open={modal.newStaffMember}
                    handleClose={() => updateModal('newStaffMember', false)}
                  />
                )}

                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  staffMembersListIsEmpty && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Card, CircularProgress, Container, Stack, Typography } from '@mui/material';
// icon
import minusFill from '@iconify/icons-eva/minus-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
// components
import { DataGridCustom } from '../../components/DataGridCustom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Label from '../../components/Label';
import Page from '../../components/Page';
import CoursesMoreMenu from './coursesMoreMenu';
import { CustomToolbar } from './coursesToolbar';
// services
import { HigherEducationsService } from '../../services/HigherEducations';
// hooks
import { useInstituitions } from '../../hooks/useInstitutions';
import { useToast } from '../../hooks/useToast';

// ----------------------------------------------------------------------

const columns = [
  // OPTIONS
  // https://material-ui.com/api/data-grid/grid-col-def/#main-content
  // - hide: false (default)
  // - editable: false (default)
  // - filterable: true (default)
  // - sortable: true (default)
  // - disableColumnMenu: false (default)

  // FIELD TYPES
  // --------------------
  // 'string' (default)
  // 'number'
  // 'date'
  // 'dateTime'
  // 'boolean'
  // 'singleSelect'

  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    filterable: false,
    sortable: false
  },
  {
    field: 'institution',
    headerName: 'Nome da Instituição de ensino',
    flex: 1
  },
  {
    field: 'acronym',
    headerName: 'Nome simples',
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    type: 'boolean',
    width: 120,
    renderCell: (params) => {
      const getStatus = params.row.status;
      return (
        <Label
          variant="ghost"
          color={(!getStatus && 'error') || (getStatus && 'success') || 'default'}
          sx={{ textTransform: 'capitalize', mx: 'auto' }}>
          {getStatus ? 'Ativo' : 'Desativado'}
        </Label>
      );
    }
  },
  {
    field: 'analyzed',
    headerName: 'Analisado',
    headerAlign: 'center',
    type: 'boolean',
    renderCell: (params) => {
      const getAnalysis = params.row.analyzed;
      return (
        <Label
          variant="ghost"
          color={(!getAnalysis && 'error') || (getAnalysis && 'success') || 'default'}
          sx={{ textTransform: 'capitalize', mx: 'auto' }}>
          {getAnalysis ? 'Sim' : 'Não'}
        </Label>
      );
    }
  },
  {
    field: 'action',
    headerName: 'Opções',
    headerAlign: 'right',
    align: 'right',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <CoursesMoreMenu data={params.row} updateDataGrid={params.api.updateRows} />
    )
  }
];

// ----------------------------------------------------------------------

const initialValues = { page: 0, pageSize: 15 };

export default function Institutions() {
  const toast = useToast();
  const [selectionModel, setSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState({ active: false, deactive: false });
  const {
    data,
    pagination,
    loading,
    filter: { handleFilterChange },
    sorting,
    refresh,
    updateRows
  } = useInstituitions({
    serviceMethod: HigherEducationsService.getPaginatedUniversities,
    initialValues
  });

  const handleFilter = ({ items }) => {
    const filters = items[0];

    handleFilterChange({
      [filters.columnField]: filters.value
    });
  };

  const selected = data.filter((row) => selectionModel.includes(row.id));
  const inactiveSelectedRow = selected
    .filter((res) => !res.status && res.analyzed)
    .map((res) => res.id);

  const activeSelectedRow = selected
    .filter((res) => res.status && res.analyzed)
    .map((res) => res.id);

  async function massUpdate(toggle) {
    if (inactiveSelectedRow.length === 0 && toggle) {
      setIsLoading({ active: false, deactive: false });
      return toast.warn('Instituições selecionadas já estão ativas ou não analisadas.');
    }
    if (activeSelectedRow.length === 0 && !toggle) {
      setIsLoading({ active: false, deactive: false });
      return toast.warn('Instituições selecionadas já estão inativas ou não analisadas.');
    }
    const response = await HigherEducationsService.massUpdateUniversities({
      ids: toggle ? inactiveSelectedRow : activeSelectedRow,
      status: toggle
    });
    if (response.isError) {
      switch (response.data.message) {
        case 'no institutions have been updated':
          setIsLoading({ active: false, deactive: false });
          return toast.error('Nenhuma instituição foi atualizada.');
        default:
          setIsLoading({ active: false, deactive: false });
          return toast.error('Erro desconhecido. Contate a equipe de tecnologia.');
      }
    }
    toast.success(
      `${toggle ? inactiveSelectedRow.length : activeSelectedRow.length} instituições ${
        toggle ? 'ativadas' : 'desativadas'
      }!`
    );
    setIsLoading({ active: false, deactive: false });
    return updateRows(toggle ? inactiveSelectedRow : activeSelectedRow);
  }

  return (
    <Page title="Instituições de ensino">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HeaderBreadcrumbs
            heading="Instituições de ensino"
            links={[
              { name: 'Início', href: '/dashboard/app' },
              { name: 'Usuários', href: '/dashboard/users' },
              { name: 'Instituições de ensino' }
            ]}
          />
        </Stack>
        {selected.length > 0 && (
          <Stack
            direction="row"
            py={3}
            px={5}
            my={2}
            borderRadius={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: 'pink' }}>
            <Typography variant="body">
              <strong>{selected.length}</strong>{' '}
              {selected.length === 1 ? 'instituição selecionada' : 'instituições selecionadas'}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                component={RouterLink}
                to="#"
                color="success"
                onClick={() => {
                  massUpdate(true);
                  setIsLoading({ ...isLoading, active: true });
                }}
                startIcon={
                  isLoading.active ? (
                    <CircularProgress
                      size="0.75em"
                      thickness={7}
                      disableShrink
                      color="inherit"
                      sx={{ ml: 0.75 }}
                    />
                  ) : (
                    <Icon icon={plusFill} />
                  )
                }>
                Ativar
              </Button>
              <Button
                variant="contained"
                component={RouterLink}
                to="#"
                color="warning"
                onClick={() => {
                  massUpdate(false);
                  setIsLoading({ ...isLoading, deactive: true });
                }}
                startIcon={
                  isLoading.deactive ? (
                    <CircularProgress
                      size="0.75em"
                      thickness={7}
                      disableShrink
                      color="inherit"
                      sx={{ ml: 0.75 }}
                    />
                  ) : (
                    <Icon icon={minusFill} />
                  )
                }>
                Desativar
              </Button>
            </Stack>
          </Stack>
        )}

        <Card>
          <DataGridCustom
            checkboxSelection
            hideFooterSelectedRowCount
            getRowId={(dataItem) => dataItem.id}
            data={data}
            columns={columns}
            pagination={pagination}
            sorting={sorting}
            filterMode="server"
            onFilterModelChange={handleFilter}
            loading={loading}
            NoRowsOverlay="Nenhuma instituição encontrado"
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            components={{
              Toolbar: () => CustomToolbar({ addTotalNumber: pagination.addTotalNumber, refresh })
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}

import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#EEC4CD',
  light: '#E07B91',
  main: '#C93B59',
  dark: '#A13048',
  darker: '#551B27',
  contrastText: '#fff',
  500_8: alpha('#C93B59', 0.08),
  500_12: alpha('#C93B59', 0.12),
  500_16: alpha('#C93B59', 0.16),
  500_24: alpha('#C93B59', 0.24),
  500_32: alpha('#C93B59', 0.32),
  500_48: alpha('#C93B59', 0.48),
  gstart: '#EEC4CD',
  gfinish: '#C93B59'
};
const SECONDARY = {
  lighter: '#D7E9FE',
  light: '#A3BED9',
  main: '#3B4B5B',
  dark: '#202A33',
  darker: '#141B21',
  contrastText: '#fff',
  500_8: alpha('#3B4B5B', 0.08),
  500_12: alpha('#3B4B5B', 0.12),
  500_16: alpha('#3B4B5B', 0.16),
  500_24: alpha('#3B4B5B', 0.24),
  500_32: alpha('#3B4B5B', 0.32),
  500_48: alpha('#3B4B5B', 0.48),
  gstart: '#ACC5DE',
  gfinish: '#192128'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
  500_8: alpha('#1890FF', 0.08),
  500_12: alpha('#1890FF', 0.12),
  500_16: alpha('#1890FF', 0.16),
  500_24: alpha('#1890FF', 0.24),
  500_32: alpha('#1890FF', 0.32),
  500_48: alpha('#1890FF', 0.48),
  gstart: '#74CAFF',
  gfinish: '#0C53B7'
};
const SUCCESS = {
  lighter: '#CFFFEE',
  light: '#91F6D3',
  main: '#47EDB3',
  dark: '#43C295',
  darker: '#1D674D',
  contrastText: GREY[800],
  500_8: alpha('#47EDB3', 0.08),
  500_12: alpha('#47EDB3', 0.12),
  500_16: alpha('#47EDB3', 0.16),
  500_24: alpha('#47EDB3', 0.24),
  500_32: alpha('#47EDB3', 0.32),
  500_48: alpha('#47EDB3', 0.48),
  gstart: '#91F6D3',
  gfinish: '#185741'
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#F7BD2A',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
  500_8: alpha('#F7BD2A', 0.08),
  500_12: alpha('#F7BD2A', 0.12),
  500_16: alpha('#F7BD2A', 0.16),
  500_24: alpha('#F7BD2A', 0.24),
  500_32: alpha('#F7BD2A', 0.32),
  500_48: alpha('#F7BD2A', 0.48),
  gstart: '#FFE16A',
  gfinish: '#D49800'
};
const ERROR = {
  lighter: '#FFDAE2',
  light: '#F597AC',
  main: '#F73E66',
  dark: '#C83756',
  darker: '#7A0C2E',
  contrastText: '#fff',
  500_8: alpha('#F73E66', 0.08),
  500_12: alpha('#F73E66', 0.12),
  500_16: alpha('#F73E66', 0.16),
  500_24: alpha('#F73E66', 0.24),
  500_32: alpha('#F73E66', 0.32),
  500_48: alpha('#F73E66', 0.48),
  gstart: '#F5A7B8',
  gfinish: '#DD0030'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

export const light = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  mode: 'light',
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#f6f6f6', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};
export const dark = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  mode: 'dark',
  text: { primary: GREY[400], secondary: GREY[500], disabled: GREY[600] },
  background: { paper: '#252525', default: '#202020', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

import { api } from '..';
import { MANAGEMENT } from '../routes';

class ManagementService {
  async listStaffMembers() {
    const res = await api.get(MANAGEMENT.LIST_STAFF);
    return res;
  }

  async addNewStaffMember(payload) {
    const res = await api.post(MANAGEMENT.ADD_MEMBER, payload);
    return res;
  }

  async activateStaffMember(payload) {
    const res = await api.patch(MANAGEMENT.ACTIVATE_MEMBER, payload);
    return res;
  }

  async deactivateStaffMember(payload) {
    const res = await api.patch(MANAGEMENT.DEACTIVATE_MEMBER, payload);
    return res;
  }

  async updateStaffMemberAuthority(payload) {
    const res = await api.patch(MANAGEMENT.UPDATE_AUTHORITY, payload);
    return res;
  }
}

export const managementService = new ManagementService();

// form
import { Controller } from 'react-hook-form';
// @mui
import { SketchPicker } from 'react-color';

// ----------------------------------------------------------------------

export function RHFSketchPicker({ name, control, ...other }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <SketchPicker color={value} onChangeComplete={onChange} {...other} />
      )}
    />
  );
}

import { MoreVert } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import { useRef, useState } from 'react';
import SvgIconStyle from '../../components/SvgIconStyle';
import { useToast } from '../../hooks/useToast';
import { CoursesService } from '../../services/courses';
import { AnalysisModal } from './analysisModal';
import { EditModal } from './editModal';

export function Actions({ id, status, analyzed, api, courseName }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef(null);
  const toast = useToast();

  const changeStatus = async (id, newStatus) => {
    setLoading(true);
    await CoursesService.editCourse({ id, status: newStatus }).then((res) => {
      if (res.status === 201) {
        const { data } = res.data;
        api.updateRows([{ id, status: data.status }]);
        setOpenMenu(false);
        setLoading(false);
        toast.success(`${courseName} foi ${newStatus ? 'ativado' : 'desativado'}.`);
      }
    });
  };

  return (
    <>
      <IconButton ref={anchorEl} onClick={() => setOpenMenu(true)}>
        <MoreVert />
      </IconButton>
      <Menu open={openMenu} onClose={() => setOpenMenu(false)} anchorEl={anchorEl.current}>
        {!analyzed
          ? [
              <MenuItem
                key={0}
                onClick={() => {
                  setOpenAnalysisModal(true);
                  setOpenMenu(false);
                }}>
                <ListItemIcon>
                  <SvgIconStyle src="/static/icons/ic_verified.svg" />
                </ListItemIcon>
                <ListItemText>Analisar informações</ListItemText>
              </MenuItem>
            ]
          : [
              <MenuItem
                key={1}
                onClick={() => {
                  setOpenEditModal(true);
                  setOpenMenu(false);
                }}>
                <ListItemIcon>
                  <SvgIconStyle src="/static/icons/ic_edit.svg" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>,

              <MenuItem
                key={2}
                onClick={() => changeStatus(id, !status)}
                sx={{
                  color: !status ? 'success.dark' : 'error.dark'
                }}>
                <ListItemIcon>
                  <SvgIconStyle src={`/static/icons/ic_${!status ? 'plus' : 'minus'}.svg`} />
                </ListItemIcon>
                <ListItemText>
                  {!status ? 'Ativar' : 'Desativar'}{' '}
                  {loading && (
                    <CircularProgress
                      size="0.75em"
                      thickness={7}
                      disableShrink
                      color={!status ? 'success' : 'error'}
                      sx={{ ml: 0.75 }}
                    />
                  )}
                </ListItemText>
              </MenuItem>
            ]}
      </Menu>

      <EditModal
        open={openEditModal}
        close={() => setOpenEditModal(false)}
        id={id}
        updateDataGrid={api.updateRows}
        defaultName={courseName}
      />

      <AnalysisModal
        open={openAnalysisModal}
        close={() => setOpenAnalysisModal(false)}
        courseData={{
          id,
          status,
          analyzed,
          course: courseName
        }}
        updateDataGrid={api.updateRows}
      />
    </>
  );
}

import { Typography } from '@mui/material';
import { getGridStringOperators } from '@mui/x-data-grid';
import Label from '../../components/Label';
import { Actions } from './actions';

export const columns = [
  { field: 'id', headerName: 'ID', width: 200, hide: true, sortable: false, filterable: false },
  {
    field: 'course',
    headerName: 'Nome do curso',
    width: 300,
    filterOperators: getGridStringOperators().filter((op) => op.value === 'contains'),
    renderCell: ({ value }) => (
      <Typography fontSize="auto" title={value}>
        {value}
      </Typography>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'boolean',
    width: 100,
    // sortable: false,
    renderCell: ({ value }) => (
      <Label color={value ? 'success' : 'error'}>{value ? 'Ativo' : 'Inativo'}</Label>
    )
  },
  {
    field: 'analyzed',
    headerName: 'Analisado',
    type: 'boolean',
    width: 100,
    // sortable: false,
    renderCell: ({ value }) => (
      <Label color={value ? 'success' : 'error'}>{value ? 'Sim' : 'Não'}</Label>
    )
  },
  {
    field: 'action',
    headerName: 'Opções',
    width: 80,
    align: 'right',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <Actions
        id={params.row.id}
        courseName={params.row.course}
        status={params.row.status}
        analyzed={params.row.analyzed}
        api={params.api}
      />
    )
  }
];

import { useCallback, useRef, useState } from 'react';
import { useCleanupEffect } from './useCleanupEffect';

/**
 *
 * @param {{serviceMethod: () => Promise<{data}>, initialValues: { page: number, pageSize: number, sortingOrder: 'asc' | 'desc', orderBy: 'createdAt' | string }}} param0
 * @returns
 */
export function usePagination({
  serviceMethod,
  initialValues = {
    page: 0,
    orderBy: 'createdAt',
    pageSize: 15,
    sortingOrder: 'desc'
  },
  debouncerTimeout = 300
}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(initialValues?.page);
  const [pageSize, setPageSize] = useState(initialValues?.pageSize);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState(initialValues?.orderBy);
  const [sortingOrder, setSortingOrder] = useState(initialValues?.sortingOrder);
  const timeout = useRef(null);
  useCleanupEffect(
    (state) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setLoading(true);
        const ascending = sortingOrder === 'asc';
        fetchPages({ pageSize, page, orderBy, ascending }).then((res) => {
          const { data, total } = res.data;
          if (state.isMounted) {
            setData(data);
            setTotal(total);
            setLoading(false);
          }
        });
      }, debouncerTimeout);
    },
    [page, pageSize, orderBy, sortingOrder]
  );

  const fetchPages = useCallback(
    async ({ pageSize, page, orderBy, ascending }) => {
      const response = await serviceMethod({ limit: pageSize, page, orderBy, ascending });
      return response;
    },
    [serviceMethod]
  );

  const handlePageChange = useCallback((nextPage) => {
    setPage(nextPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const handleSortModelChange = useCallback(([sortingModel]) => {
    setOrderBy(sortingModel?.field);
    setSortingOrder(sortingModel?.sort);
  }, []);

  return {
    data,
    loading,
    pagination: { page, pageSize, total, handlePageChange, handlePageSizeChange },
    sorting: { sortingOrder, orderBy, handleSortModelChange }
  };
}

import { createContext, useMemo, useState } from 'react';

export const UserListContext = createContext({ data: { data: [] } });

export function UserListProvider({ children }) {
  const [data, setData] = useState({ data: [], total: 0 });
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  const savePage = (values) => {
    setPage(values);
  };

  const saveLoading = (values) => {
    setLoading(values);
  };

  const saveData = (values) => {
    setData(values);
  };

  const value = useMemo(
    () => ({ data, saveData, page, savePage, filters, setFilters, loading, saveLoading }),
    [data, loading, filters, page]
  );
  return <UserListContext.Provider value={value}>{children}</UserListContext.Provider>;
}

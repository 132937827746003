import { useContext, useEffect } from 'react';
import { UserListContext } from '../contexts/UserListContext';
import { UsersService } from '../services/users';

export function useUserList() {
  const { data, saveData, page, savePage, filters, setFilters, loading, saveLoading } =
    useContext(UserListContext);

  useEffect(() => {
    getUserList({ page, ...filters });
  }, [page]);

  //

  useEffect(() => {
    getUserList({ page: 0, ...filters });
  }, [filters]);

  const getUserList = async (params) => {
    await saveLoading(true);
    const response = await UsersService.getPaginated(params);
    await saveData(response.data);
    await saveLoading(false);
  };

  return {
    data,
    saveData,
    getUserList,
    page,
    savePage,
    setFilters,
    filters,
    loading,
    saveLoading
  };
}

import { api } from '../index';

export class HigherEducationsService {
  static async getUniversities(params) {
    return api.get('/highereducations/universities', { params });
  }

  static async getCourses(params) {
    return api.get('/highereducations/courses', { params });
  }

  static async getInstitutions({ institution, acronym, status, analyzed, sortingOrder, orderBy }) {
    return api.get('/institutions', {
      params: { institution, acronym, status, analyzed, sortingOrder, orderBy }
    });
  }

  static async getPaginatedUniversities({
    page,
    limit,
    institution,
    acronym,
    status,
    analyzed,
    sortingOrder,
    orderBy
  }) {
    const res = await api.get('/institutions', {
      params: {
        page,
        limit,
        institution,
        acronym,
        status,
        analyzed,
        sortingOrder,
        orderBy
      }
    });
    return res;
  }

  static async getTotalUniversities({ status, analyzed, institution }) {
    const res = await api.get('/counts/institutions', {
      params: { status, analyzed, institution }
    });
    return res;
  }

  static async editUniversities({ id, institution, acronym }) {
    const res = await api.put(`/institutions/${id}`, {
      payload: {
        institution,
        acronym
      }
    });
    return res;
  }

  static async createUniversities({ institution, acronym }) {
    const res = await api.post('/institutions', {
      payload: {
        institution,
        acronym
      }
    });
    return res;
  }

  static async updateUniversities({ id, institution, acronym, status, analyzed }) {
    const res = await api.put(`/institutions/${id}`, {
      payload: {
        institution,
        acronym,
        status,
        analyzed
      }
    });
    return res;
  }

  static async replaceUniversity({ idInstitutionToBeReplaced, idNewInstitution }) {
    const res = await api.put(
      `/institutions/${idInstitutionToBeReplaced}/replace/${idNewInstitution}`
    );
    return res;
  }

  static async massUpdateUniversities({ ids, status }) {
    const res = await api.put('/institutions/update-many/status', {
      payload: {
        ids,
        status
      }
    });
    return res;
  }

  static async createUniversityLogo({ id, logo }) {
    const res = await api.post(`/institutions/${id}/uploadfile/logo`, logo, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res;
  }
}

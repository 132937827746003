import { api } from '../index';
import { STATS } from '../routes';

class StatsService {
  async getStats() {
    const response = await api.get(STATS.GET_STATS);
    return response;
  }
}

export const statsService = new StatsService();

import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;
if (process.env.NODE_ENV === 'development') {
  console.log({ backendURL });
}

export const api = axios.create({ baseURL: backendURL, withCredentials: true });

function onRequest(config) {
  const token = localStorage.getItem('auth.token');

  if (token) {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

function onResponseResolve(response) {
  return response;
}

function onResponseReject(error) {
  return { ...error.response, isError: true };
}

api.interceptors.request.use(onRequest);
api.interceptors.response.use(onResponseResolve, onResponseReject);

// form
import { Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

import { NumericFormat, PatternFormat } from 'react-number-format';

import { isArray } from 'lodash';

// ----------------------------------------------------------------------

export function RHFTextField({ name, control, ...other }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} />
      )}
    />
  );
}

export function RHFPatternTextField({ name, control, onChangeValue = 'value', ...other }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PatternFormat
          value={field.value}
          onValueChange={(values) => {
            if (isArray(onChangeValue)) field.onChange(values);
            else field.onChange(values[onChangeValue]);
          }}
          customInput={TextField}
          fullWidth
          error={!!error}
          helperText={error?.message}
          valueIsNumericString
          {...other}
        />
      )}
    />
  );
}

export function RHFNumberTextField({ name, control, onChangeValue = 'floatValue', ...other }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <NumericFormat
          value={field.value}
          onValueChange={(values) => {
            if (isArray(onChangeValue)) field.onChange(values);
            else field.onChange(values[onChangeValue]);
          }}
          customInput={TextField}
          fullWidth
          error={!!error}
          helperText={error?.message}
          allowNegative={false}
          decimalSeparator=","
          thousandSeparator="."
          {...other}
        />
      )}
    />
  );
}

/* eslint import/newline-after-import: "off" */

import { Button, Divider, Drawer, Stack, Typography } from '@mui/material';
import qs from 'qs';
import { useContext, useEffect, useState } from 'react';
import { UserListContext } from '../contexts/UserListContext';
import { CompanyService } from '../services/companies';
import { CoursesService } from '../services/courses';
import { HigherEducationsService } from '../services/HigherEducations';
import { ProcessService } from '../services/processes';
import { StateService } from '../services/States';
import { TechnicalSkillsService } from '../services/TechnicalSkills';

import { Select } from './form-inputs/Autocomplete';
import { DateInput } from './form-inputs/Date';

const EMPTY_QUERIES = {
  availability: [],
  institutions: [],
  courses: [],
  graduationSituation: null,
  graduationConclusion: null,
  graduationType: [],
  graduationModality: [],
  technicalSkills: [],
  candidateLocationState: null,
  candidateLocationCity: null,
  graduationLocation: null,
  companies: [],
  processes: []
};

export default function FilterSidebar({ isOpen, onClose, getUserList, setIsOpen }) {
  const [universities, setUniversities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [states, setStates] = useState([]);
  const [stateCandidateCities, setStateCandidateCities] = useState([]);
  const [courses, setCourses] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [processes, setProcesses] = useState([]);

  const [queries, setQueries] = useState(EMPTY_QUERIES);

  const { setFilters } = useContext(UserListContext);

  const clearAll = async () => {
    setFilters({});
    getUserList({ page: 0 });
    setQueries(EMPTY_QUERIES);
  };

  const getStates = async () => {
    const response = await StateService.getStates();
    setStates(response.data);
  };

  const getCompanies = async () => {
    let response = await CompanyService.getCompanies();
    response = response.data.companies.map((company) => ({
      label: company.companyName || '[sem nome]',
      value: company._id
    }));
    setCompanies(response);
  };

  const getUniversities = async () => {
    await HigherEducationsService.getInstitutions({
      status: true,
      analyzed: true
    }).then((res) => {
      setUniversities(
        res.data.data.map((inst) => ({
          label: inst.institution,
          value: inst.id
        }))
      );
    });
  };

  const getCourses = async () => {
    await CoursesService.getCourses({
      status: true,
      analyzed: true
    }).then((res) => {
      setCourses(
        res.data.data.map((c) => ({
          label: c.course,
          value: c.id
        }))
      );
    });
  };

  useEffect(() => {
    if (queries.companies.length) getProcesses();
    else changeQueryValue('processes', []);
  }, [queries.companies]);

  const getProcesses = async () => {
    try {
      const companies = qs.stringify(queries.companies.map((company) => company.value));
      let response = await ProcessService.getProcessByCompanies({ companies });
      response = response.data.map((process) => ({ label: process.title, value: process._id }));
      setProcesses(response);
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const getTechnicalSkills = async () => {
    let response = await TechnicalSkillsService.getTechnicalSkills();
    response = response.data.skills.map((technicalSkill) => ({
      label: technicalSkill.name,
      value: technicalSkill._id
    }));
    setTechnicalSkills(response);
  };

  const search = async () => {
    setIsOpen(false);
    const localFilters = {};
    if (queries.availability.length) {
      localFilters.availabilityQuery = qs.stringify(
        queries.availability.map((availability) => availability.value)
      );
    }

    if (queries.institutions.length) {
      localFilters.universitiesQuery = qs.stringify(queries.institutions.map((uni) => uni.value));
    }

    if (queries.courses.length) {
      localFilters.coursesQuery = qs.stringify(queries.courses.map((course) => course.value));
    }

    if (queries.graduationSituation) {
      localFilters.graduationSituationQuery = queries.graduationSituation.value;
    }

    if (queries.graduationConclusion) {
      localFilters.graduationConclusionQuery = queries.graduationConclusion;
    }

    if (queries.graduationType.length) {
      localFilters.graduationTypeQuery = qs.stringify(
        queries.graduationType.map((type) => type.value)
      );
    }

    if (queries.graduationModality.length) {
      localFilters.graduationModalityQuery = qs.stringify(
        queries.graduationModality.map((mod) => mod.value)
      );
    }

    if (queries.technicalSkills.length) {
      localFilters.technicalSkillsQuery = qs.stringify(
        queries.technicalSkills.map((skill) => skill.value)
      );
    }

    if (queries.candidateLocationCity) {
      localFilters.candidateLocationQuery = queries.candidateLocationCity.value;
    }

    if (queries.graduationLocation) {
      localFilters.graduationLocationQuery = queries.graduationLocation.value;
    }

    if (queries.processes.length) {
      localFilters.processQuery = qs.stringify(queries.processes.map((process) => process.value));
    }

    await setFilters({ ...localFilters });
  };

  const changeQueryValue = (query, newValue) => {
    setQueries((prev) => ({ ...prev, [query]: newValue }));
  };

  useEffect(() => {
    getStates();
    getUniversities();
    getCourses();
    getTechnicalSkills();
    getCompanies();
  }, []);

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          Filtros
        </Typography>
      </Stack>

      <Divider />
      <Stack spacing={3} sx={{ p: 3 }}>
        <Stack spacing={1} sx={{ width: 250 }}>
          <Typography variant="subtitle1">Candidatos Disponíveis</Typography>
          <Select
            checkboxes
            onChange={(_, newValue) => {
              changeQueryValue('availability', newValue);
            }}
            value={queries.availability}
            options={[
              { label: 'Trabalho não remunerado', value: 'unpaid' },
              { label: 'Trabalho remoto', value: 'remote' },
              { label: 'Trabalho presencial', value: 'presencial' },
              { label: 'Trabalho integral', value: 'fullTime' },
              { label: 'Trabalho meio-período', value: 'halfTime' },
              { label: 'Estagio de férias', value: 'summerInternJobs' },
              { label: 'Estagio regular', value: 'internJobs' },
              { label: 'Analista', value: 'analyst' },
              { label: 'Contrato PJ', value: 'pj' },
              { label: 'Contrato CLT', value: 'clt' }
            ]}
            label="Disponibilidade dos candidatos"
            isOptionEqualToValue={(op, value) => op.value === value.value}
          />
        </Stack>

        <Stack spacing={1} sx={{ width: 250 }}>
          <Typography variant="subtitle1">Instituição de Ensino</Typography>
          <Select
            checkboxes
            options={universities}
            label="Instituições"
            value={queries.institutions}
            onChange={(_, newValue) => changeQueryValue('institutions', newValue)}
          />
        </Stack>

        <Stack spacing={1} sx={{ width: 250 }}>
          <Typography variant="subtitle1">Cursos</Typography>
          <Select
            checkboxes
            options={courses}
            label="Cursos"
            value={queries.courses}
            onChange={(_, newValue) => changeQueryValue('courses', newValue)}
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="subtitle1">Situação do curso</Typography>
          <Select
            options={[
              { value: 'andamento', label: 'Em andamento' },
              { value: 'concluido', label: 'Concluído' }
            ]}
            label="Situação"
            value={queries.graduationSituation}
            onChange={(_, newValue) => changeQueryValue('graduationSituation', newValue)}
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="subtitle1">Conclusão do curso (data mínima)</Typography>
          <DateInput
            label="Data de conclusão"
            value={queries.graduationConclusion}
            onChange={(newValue) => {
              changeQueryValue('graduationConclusion', newValue);
            }}
          />
        </Stack>

        <Stack spacing={1} sx={{ width: 250 }}>
          <Typography variant="subtitle1">Tipo do curso</Typography>
          <Select
            checkboxes
            options={[
              { value: 'curso livre', label: 'Curso livre' },
              { value: 'ensino médio', label: 'Ensino médio' },
              { value: 'ensino técnico', label: 'Ensino técnico' },
              { value: 'ensino superior', label: 'Ensino superior' },
              { value: 'pós-graduação', label: 'Pós-graduação' }
            ]}
            label="Tipo do curso"
            value={queries.graduationType}
            onChange={(_, newValue) => changeQueryValue('graduationType', newValue)}
            isOptionEqualToValue={(op, value) => op.value === value.value}
          />
        </Stack>

        <Stack spacing={1} sx={{ width: 250 }}>
          <Typography variant="subtitle1">Modalidade do curso</Typography>
          <Select
            checkboxes
            options={[
              { value: 'presencial', label: 'Presencial' },
              { value: 'ead', label: 'Ensino a distância' },
              { value: 'semipresencial', label: 'Híbrido' }
            ]}
            label="Modalidade do curso"
            value={queries.graduationModality}
            onChange={(_, newValue) => changeQueryValue('graduationModality', newValue)}
          />
        </Stack>

        <Stack spacing={1} sx={{ width: 250 }}>
          <Typography variant="subtitle1">Habilidades Técnicas</Typography>
          <Select
            checkboxes
            options={technicalSkills}
            value={queries.technicalSkills}
            onChange={(_, newValue) => changeQueryValue('technicalSkills', newValue)}
            label="Habilidades técnicas"
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="subtitle1">Localidade do candidato</Typography>

          <Select
            label="Estado do candidato"
            options={states.map((state) => ({
              label: state.nome,
              value: state.nome
            }))}
            value={queries.candidateLocationState}
            onChange={(_, newValue) => {
              const selectedState = states.find((state) => state.nome === newValue.value);
              setStateCandidateCities(selectedState.cities);
              changeQueryValue('candidateLocationState', newValue);
              changeQueryValue('candidateLocationCity', null);
            }}
            // isOptionEqualToValue={(op, value) => {
            //   console.log(op, value);
            //   return op.value === value;
            // }}
          />

          <Select
            label="Cidade do candidato"
            options={stateCandidateCities.map((city) => ({
              label: city.nome,
              value: city.nome
            }))}
            value={queries.candidateLocationCity}
            disabled={!queries.candidateLocationState}
            onChange={(_, newValue) => changeQueryValue('candidateLocationCity', newValue)}
            // isOptionEqualToValue={(op, value) => op.value === value}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle1">Localidade da Instituição</Typography>

          <Select
            label="Estado da instituição"
            options={states.map((state) => ({
              label: state.nome,
              value: state.nome
            }))}
            value={queries.graduationLocation}
            onChange={(_, newValue) => changeQueryValue('graduationLocation', newValue)}
          />
        </Stack>

        <Stack spacing={1} sx={{ width: 250 }}>
          <Stack>
            <Typography variant="subtitle1">Empresas</Typography>

            <Select
              checkboxes
              label="Empresas"
              options={companies}
              value={queries.companies}
              onChange={(_, newValue) => {
                changeQueryValue('companies', newValue);
              }}
            />
          </Stack>

          <Stack>
            <Select
              checkboxes
              label="Processos"
              options={processes}
              value={queries.processes}
              onChange={(_, newValue) => changeQueryValue('processes', newValue)}
              disabled={!queries.companies.length}
            />
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <Stack p={3} gap={2}>
        <Button
          fullWidth
          onClick={clearAll}
          size="large"
          type="submit"
          color="inherit"
          variant="outlined">
          Limpar filtros
        </Button>

        <Button
          fullWidth
          onClick={search}
          size="large"
          type="submit"
          color="primary"
          variant="contained">
          Buscar
        </Button>
      </Stack>
    </Drawer>
  );
}

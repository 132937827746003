import { Card, Container, Stack, Typography } from '@mui/material';
import { DataGridCustom } from '../components/DataGridCustom';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { usePagination } from '../hooks/usePagination';
import { ProcessService } from '../services/processes';
import { formatCurrency } from '../utils/helpers';

const initialValues = { page: 0, pageSize: 15, sortingOrder: 'desc', orderBy: 'createdAt' };

export default function Process() {
  const { data, loading, pagination, sorting } = usePagination({
    serviceMethod: ProcessService.getPaginated,
    initialValues
  });

  return (
    <Page title="Staff | Preparo">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Processos
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <DataGridCustom
              data={data}
              columns={columns}
              pagination={pagination}
              sorting={sorting}
              loading={loading}
            />
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

const formatDate = ({ value }) => {
  const date = new Date(value);
  return <Typography title={date}>{date?.toLocaleString()}</Typography>;
};

const columns = [
  { field: '_id', headerName: 'ID', width: 200, hide: true },
  {
    field: 'title',
    headerName: 'Título',
    width: 200,
    renderCell: ({ value }) => (
      <Typography fontSize="auto" title={value}>
        {value}
      </Typography>
    )
  },
  {
    field: 'company',
    headerName: 'Empresa',
    width: 200,
    disableReorder: true,
    renderCell: ({ value }) => (
      <Typography fontSize="auto" title={value.companyName}>
        {value.companyName}
      </Typography>
    )
  },
  {
    field: 'vacancyType',
    headerName: 'Tipo de Vaga',
    width: 200,
    disableReorder: true,
    valueGetter: ({ value }) => value.title
  },
  { field: 'vacanciesAmount', headerName: 'Qtd. de Vagas', width: 100 },
  {
    field: 'isActive',
    headerName: 'Status',
    width: 200,
    valueGetter: ({ value }) => (value ? 'Ativo' : 'Encerrado')
  },
  {
    field: 'location',
    headerName: 'Localização',
    width: 200,
    valueGetter: ({ value }) => value.locationType
  },
  {
    field: 'salary',
    headerName: 'Remuneração',
    width: 250,
    renderCell: ({ value }) => {
      if (!value) return <Typography>Não informado</Typography>;
      const isPaid = typeof value.min === 'number' && typeof value.max === 'number';
      const salaryRange = isPaid
        ? `(${formatCurrency(value.min)} - ${formatCurrency(value.max)})`
        : '';
      const title = isPaid ? `${value.salaryType} ${salaryRange}` : value.salaryType;
      return (
        <Typography title={title} fontSize="auto">
          <span>{value.salaryType}</span>
          {isPaid && (
            <>
              <br />
              <span>{salaryRange}</span>
            </>
          )}
        </Typography>
      );
    }
  },
  {
    field: 'subscriptionExpirationDate',
    headerName: 'Data de Expiração',
    width: 200,
    renderCell: formatDate
  },
  { field: 'createdAt', headerName: 'Data de Criação', width: 200, renderCell: formatDate }
];

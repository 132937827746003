import { Brightness4TwoTone, Brightness7TwoTone } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { useContext } from 'react';
import { ColorModeContext } from '../../theme';

export function ToggleDarkMode() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="default">
      {theme.palette.mode === 'dark' ? <Brightness4TwoTone /> : <Brightness7TwoTone />}
    </IconButton>
  );
}

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogContentText,
  TextField,
  Typography
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import {
  RHFCombobox,
  RHFRadioGroup,
  RHFTextField,
  RHFUploadAvatar
} from '../../components/hook-form';
import Modal from '../../components/Modal';
import SearchNotFound from '../../components/SearchNotFound';
import { useDebouncerEffect } from '../../hooks/useDebouncerEffect';
import { useToast } from '../../hooks/useToast';
import { HigherEducationsService } from '../../services/HigherEducations';
import { fData } from '../../utils/formatNumber';

export default function AnalysisModal({ open, setOpen, data, updateDataGrid }) {
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [newLogo, setNewLogo] = useState(false);
  const toast = useToast();
  const schema = yup.object().shape({
    courseToBeLinked: yup
      .object({
        id: yup.string(),
        course: yup.string()
      })
      .when('shouldLinkToExistingInstitution', {
        is: 'shouldLink',
        then: (schema) => schema.required('É obrigatório escolher um curso para ser vinculado.'),
        otherwise: (schema) => schema.nullable()
      }),
    shouldLinkToExistingInstitution: yup
      .mixed()
      .oneOf(['shouldLink', 'shouldNotLink'], 'É obrigatório selecionar uma dessas opções.')
      .required('É obrigatório selecionar uma dessas opções.'),
    shouldActivateNewInstitution: yup
      .mixed()
      .oneOf(['shouldActivate', 'shouldNotActivate'], 'É obrigatório selecionar uma dessas opções.')
      .required('É obrigatório selecionar uma dessas opções.'),
    newInstitution: yup.string().when('shouldLinkToExistingInstitution', {
      is: 'shouldNotLink',
      then: (schema) => schema.required('É obrigatório o nome do novo curso.'),
      otherwise: (schema) => schema
    }),
    newAcronym: yup.string().when('shouldLinkToExistingInstitution', {
      is: 'shouldNotLink',
      then: (schema) => schema.required('É obrigatório o acrônimo do novo curso.'),
      otherwise: (schema) => schema
    })
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    setError
  } = useForm({
    defaultValues: {
      institutionToBeLinked: null,
      shouldLinkToExistingInstitution: '',
      shouldActivateNewInstitution: '',
      newInstitution: data.institution,
      newAcronym: data.acronym,
      logoUrl: data.universityURL
    },
    resolver: yupResolver(schema)
  });

  const shouldLinkToExistingInstitution = useWatch({
    name: 'shouldLinkToExistingInstitution',
    control
  });

  const submit = async (rhfData) => {
    if (rhfData.shouldLinkToExistingInstitution === 'shouldLink') {
      const res = await HigherEducationsService.replaceUniversity({
        idInstitutionToBeReplaced: data.id,
        idNewInstitution: rhfData.institutionToBeLinked.id
      });

      if (res.status === 200) {
        await HigherEducationsService.updateUniversities({
          id: rhfData.institutionToBeLinked.id,
          status: rhfData.shouldActivateNewInstitution === 'shouldActivate'
        }).then((res) => {
          if (res.status === 201) {
            updateDataGrid([{ id: data.id, _action: 'delete' }]);
            updateDataGrid([{ ...res.data.data }]);
            toast.success(
              `${data.institution} foi vinculado a ${res.data.data.institution} com sucesso!`
            );
            handleClose();
          }
          setError('institutionToBeLinked', {
            message: 'Houve um erro na vinculação desse curso.'
          });
        });
      } else {
        setError('institutionToBeLinked', { message: 'Houve um erro na vinculação desse curso.' });
      }
    } else if (rhfData.shouldLinkToExistingInstitution === 'shouldNotLink') {
      await HigherEducationsService.updateUniversities({
        id: data.id,
        institution: rhfData.newInstitution,
        acronym: rhfData.newAcronym,
        status: rhfData.shouldActivateNewInstitution === 'shouldActivate',
        analyzed: true
      }).then((res) => {
        if (res.status === 201) {
          updateDataGrid([res.data.data]);
          toast.success(`A instituição "${rhfData.newInstitution}" foi criada com sucesso!`);
          handleClose();
        }
        setError('newInstitution', {
          message:
            'Houve um erro na criação dessa instituição. Verifique se já não existe uma instituição com o mesmo nome.'
        });
        setError('newAcronym', {
          message:
            'Houve um erro na criação dessa instituição. Verifique se já não existe uma instituição com o mesmo acrônimo.'
        });
      });
      if (newLogo) {
        updateLogo(rhfData);
      }
    }
  };

  async function updateLogo(res) {
    const logo = new FormData();
    logo.append('logo', res.logoUrl);
    const logoResponse = await HigherEducationsService.createUniversityLogo({
      id: data.id,
      logo
    });
    if (logoResponse.isError) {
      return toast.error(logoResponse.data.message);
    }
    setNewLogo(false);
    return updateDataGrid([logoResponse.data.data]);
  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setNewLogo(true);
        setValue(
          'logoUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
      }
    },
    [setValue]
  );

  useDebouncerEffect(
    async () => {
      let isMounted = true;
      if (searchQuery) {
        await HigherEducationsService.getInstitutions({
          institution: searchQuery,
          status: true,
          analyzed: true
        }).then((res) => {
          if (isMounted) {
            if (res.status === 200) {
              setSearchResults(res.data.data);
            } else {
              setSearchResults([]);
            }
          }
        });
      }
      return () => (isMounted = false);
    },
    [searchQuery],
    500
  );

  function handleClose() {
    setOpen(false);
  }

  return (
    <Modal
      title="Análise de registro"
      open={open}
      submitButtonText={
        <>
          Salvar análise{' '}
          {isSubmitting && (
            <CircularProgress
              size="0.75em"
              thickness={7}
              disableShrink
              sx={{ ml: 0.75, color: 'inherit' }}
            />
          )}
        </>
      }
      submitButtonAction={handleSubmit(submit, () => {})}
      close={() => {
        handleClose();
      }}>
      <DialogContent>
        <DialogContentText fontWeight={500} color="#000">
          Registro do usuário
        </DialogContentText>
        <TextField
          fullWidth
          disabled
          value={data.institution}
          type="text"
          margin="dense"
          variant="outlined"
          label="Nome da instituição de ensino"
          InputLabelProps={{ shrink: data.name }}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText fontWeight={500} color="#000">
          Vincular com algum documento equivalente
        </DialogContentText>
        <RHFRadioGroup
          name="shouldLinkToExistingInstitution"
          control={control}
          options={['shouldLink', 'shouldNotLink']}
          getOptionLabel={[
            'Vincular com um dado já existente',
            'Não há nenhum dado equivalente registrado'
          ]}
        />
        <RHFCombobox
          name="institutionToBeLinked"
          control={control}
          options={searchResults}
          onInputChange={(_, newValue) => setSearchQuery(newValue)}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.institution)}
          label="Selecione uma instituição"
          disabled={shouldLinkToExistingInstitution !== 'shouldLink'}
          noOptionsText={
            searchResults.length === 0 ? '...' : <SearchNotFound searchQuery={searchQuery} />
          }
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText fontWeight={500} color="#000">
          Criar novo registro
        </DialogContentText>
        <RHFTextField
          fullWidth
          name="newInstitution"
          control={control}
          type="text"
          margin="dense"
          variant="outlined"
          label="Nome da instituição de ensino"
          disabled={shouldLinkToExistingInstitution !== 'shouldNotLink'}
        />
        <RHFTextField
          fullWidth
          name="newAcronym"
          control={control}
          type="text"
          margin="dense"
          variant="outlined"
          label="Nome simples / acrônimo (ex. USP)"
          disabled={shouldLinkToExistingInstitution !== 'shouldNotLink'}
        />
        <RHFRadioGroup
          name="shouldActivateNewInstitution"
          control={control}
          options={['shouldActivate', 'shouldNotActivate']}
          getOptionLabel={[
            'Vincular com o dado acima e ativá-lo',
            'O dado informado não será ativado'
          ]}
          row={false}
        />
      </DialogContent>
      {shouldLinkToExistingInstitution !== 'shouldLink' && (
        <DialogContent>
          <Box p={2} border="1px solid #e4e7ea" borderRadius={1}>
            <DialogContentText fontWeight={500} color="#000">
              Logo da instituição
            </DialogContentText>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="logoUrl"
                accept="image/*"
                maxSize={3145728}
                onDrop={handleDrop}
                control={control}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}>
                    Permitido *.jpeg, *.jpg, *.png.
                    <br /> Tamanho máximo de {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Box>
        </DialogContent>
      )}
    </Modal>
  );
}

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Divider,
  FormHelperText,
  Stack,
  Typography,
  darken,
  lighten
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { isString } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import {
  RHFSketchPicker,
  RHFTextField,
  RHFUploadAvatar,
  RHFUploadSingleFile
} from '../../../components/hook-form';
import { useToast } from '../../../hooks/useToast';
import { PartnersService } from '../../../services/partners';
import { LPPreview } from './LPPreview';

const schema = yup.object({
  subdomain: yup.string().required('O subdomínio é obrigatório.'),
  logo: yup.mixed().required('O logo da empresa é obrigatório.'),
  colors: yup
    .array()
    .of(
      yup.object({
        hex: yup.string()
      })
    )
    .length(2, 'As cores da empresa são obrigatórias.'),
  photoCompany: yup.mixed().required('A imagem da LP é obrigatória.'),
  photoUser: yup.mixed().required('A imagem da LP é obrigatória.'),
  toUser: yup.object({
    title: yup.string().required('O título da LP é obrigatório.'),
    subtitle: yup.string().required('O subtítulo da LP é obrigatório.')
  }),
  toCompany: yup.object({
    title: yup.string().required('O título da LP é obrigatório.'),
    subtitle: yup.string().required('O subtítulo da LP é obrigatório.')
  })
});

export function LandingPagesFormForm({ companyId }) {
  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
    handleSubmit,
    setError,
    reset
  } = useForm({
    defaultValues: {
      subdomain: '',
      toCompany: {
        title: '',
        subtitle: ''
      },
      toUser: {
        title: '',
        subtitle: ''
      },
      colors: [
        {
          hex: '#808080',
          rgb: {
            r: 128,
            g: 128,
            b: 128
          },
          hsv: {
            h: 180,
            s: 0,
            v: 50
          }
        },
        {
          hex: '#808080',
          rgb: {
            r: 128,
            g: 128,
            b: 128
          },
          hsv: {
            h: 180,
            s: 0,
            v: 50
          }
        }
      ],
      logo: '',
      photoUser: '',
      photoCompany: '',
      afterSubmit: '',
      alreadyHasLP: false
    },
    resolver: yupResolver(schema)
  });

  const toast = useToast();

  const handleDrop = useCallback(
    (acceptedFiles, name) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          name,
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
      }
    },
    [setValue]
  );

  async function submit(data) {
    try {
      const { logo, photoUser, photoCompany, afterSubmit, alreadyHasLP, ...rest } = data;
      let shouldEdit = alreadyHasLP;

      if (!shouldEdit) {
        await PartnersService.uploadLandingPageData(companyId, rest).then((res) => {
          if (res.isError) {
            if (res?.status === 400) {
              if (
                res?.data?.message === 'custom landing page is only possible for agency companies'
              ) {
                throw new Error(
                  'A funcionalidade de uma landing page personalizada é disponível apenas para empresas agência.'
                );
              } else {
                throw new Error(
                  'Houve um erro ao enviar os dados da LP. Verifique se todos campos estão preenchidos.'
                );
              }
            } else if (res?.status === 409) {
              if (res?.data?.message === 'subdomain already setted') {
                setError('subdomain', {
                  message: 'Este subdomínio já está em uso por outra empresa.'
                });
                throw new Error('Este subdomínio já está em uso por outra empresa.');
              } else {
                shouldEdit = true;
              }
            }
          }
        });
      }

      const logo_ = new FormData();
      logo_.append('logo', logo);

      const photoUser_ = new FormData();
      photoUser_.append('photo', photoUser);

      const photoCompany_ = new FormData();
      photoCompany_.append('photo', photoCompany);

      let logoUrl = isString(logo) ? logo : '';
      let photoUserUrl = isString(photoUser) ? photoUser : '';
      let photoCompanyUrl = isString(photoCompany) ? photoCompany : '';

      if (!isString(logo)) {
        logoUrl = await PartnersService.setLandingPageLogo(companyId, logo_).then((res) => {
          if (res.isError) {
            if (res?.status === 400)
              toast.error('Arquivo da logo não foi definido.', { autoClose: false });
            else if (res?.status === 500)
              toast.error(
                'Houve um erro interno ao salvar o arquivo da logo. Por favor tente novamente.',
                { autoClose: false }
              );
            else
              toast.error('Houve um erro ao enviar a logo. Por favor tente novamente.', {
                autoClose: false
              });

            throw new Error();
          } else {
            return res.data.data.logoUrl;
          }
        });
      }
      if (!isString(photoUser)) {
        photoUserUrl = await PartnersService.setLandingPagePhoto(
          companyId,
          'jovem',
          photoUser_
        ).then((res) => {
          if (res.isError) {
            if (res?.status === 400)
              toast.error('Arquivo da imagem da LP do jovem não foi definido.', {
                autoClose: false
              });
            else if (res?.status === 500)
              toast.error(
                'Houve um erro interno ao salvar o arquivo da imagem da LP do jovem. Por favor tente novamente.',
                { autoClose: false }
              );
            else
              toast.error(
                'Houve um erro ao enviar a imagem da LP do jovem. Por favor tente novamente.',
                { autoClose: false }
              );
            throw new Error();
          } else {
            return res.data.data.photoUrl;
          }
        });
      }
      if (!isString(photoCompany)) {
        photoCompanyUrl = await PartnersService.setLandingPagePhoto(
          companyId,
          'empresa',
          photoCompany_
        ).then((res) => {
          if (res.isError) {
            if (res?.status === 400)
              toast.error('Arquivo da imagem da LP da empresa não foi definido.', {
                autoClose: false
              });
            else if (res?.status === 500)
              toast.error(
                'Houve um erro interno ao salvar o arquivo da imagem da LP da empresa. Por favor tente novamente.',
                { autoClose: false }
              );
            else
              toast.error(
                'Houve um erro ao enviar a imagem da LP da empresa. Por favor tente novamente.',
                { autoClose: false }
              );
            throw new Error();
          } else {
            return res.data.data.photoUrl;
          }
        });
      }

      if (shouldEdit) {
        await PartnersService.editLandingPageData(companyId, {
          ...rest,
          // logoUrl,
          toUser: {
            title: toUser.title,
            subtitle: toUser.subtitle
            // photoUrl: photoUserUrl
          },
          toCompany: {
            title: toCompany.title,
            subtitle: toCompany.subtitle
            // photoUrl: photoCompanyUrl
          }
        }).then((res) => {
          if (res.isError) {
            if (res?.status === 400) {
              throw new Error(
                'Houve um erro ao enviar os dados da LP. Verifique se todos campos estão preenchidos.'
              );
            } else if (res?.status === 409) {
              setError('subdomain', {
                message: 'Este subdomínio já está em uso por outra empresa'
              });
              throw new Error('Este subdomínio já está em uso por outra empresa.');
            } else if (res?.status === 404) {
              throw new Error('Landing page não encontrada.');
            }
          }
        });
      }

      reset({
        ...rest,
        logo: logoUrl,
        photoUser: photoUserUrl,
        photoCompany: photoCompanyUrl,
        alreadyHasLP: true
      });

      toast.success('Dados salvos!');
    } catch (err) {
      setError('afterSubmit', {
        message:
          err.message || 'Houve um erro na submissão dos dados da LP. Por favor tente novamente.'
      });
      reset(data, { keepErrors: true });
    }
  }

  const [
    subdomain,
    [primaryColor, secondaryColor],
    toUser,
    toCompany,
    logo,
    photoCompany,
    photoUser
  ] = useWatch({
    control,
    name: ['subdomain', 'colors', 'toUser', 'toCompany', 'logo', 'photoCompany', 'photoUser']
  });

  useEffect(() => {
    const getData = async () => {
      const [user, company] = await Promise.all([
        PartnersService.getLandingPageData({ companyId, perspective: 'jovem' }).then(
          (res) => res.data.data
        ),
        PartnersService.getLandingPageData({ companyId, perspective: 'empresa' }).then(
          (res) => res.data.data
        )
      ]);

      reset({
        subdomain: user.subdomain,
        colors: user.colors,
        logo: user.logoUrl,
        photoUser: user.photoUrl,
        toUser: {
          title: user.title,
          subtitle: user.subtitle
        },
        photoCompany: company.photoUrl,
        toCompany: {
          title: company.title,
          subtitle: company.title
        },
        alreadyHasLP: true
      });
    };

    getData();
  }, [companyId]);

  return (
    <Stack gap={3} mb={5}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Sub-domínio
        </Typography>
        <Typography variant="body2" gutterBottom>
          Site: https://{subdomain || '{subdominio}'}.preparo.com.br
        </Typography>
        <RHFTextField
          control={control}
          name="subdomain"
          label="Subdomínio"
          disabled={isSubmitting}
        />
      </Card>

      <Card sx={{ p: 3 }}>
        <Typography variant="h6">Logo</Typography>
        <RHFUploadAvatar
          control={control}
          name="logo"
          accept={{ 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }}
          maxSize={3000000}
          onDrop={(files) => handleDrop(files, 'logo')}
          helperText={
            <Typography textAlign="center" color="text.secondary" my={2}>
              Permitido apenas *.jpeg, *.jpg, *.png, *.gif <br /> Tamanho máximo de 3 mb
            </Typography>
          }
          disabled={isSubmitting}
          sx={{ gridColumn: { sm: 'span 3', md: 'unset' } }}
        />
      </Card>

      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: '1fr 1fr'
        }}>
        <Card sx={{ p: 3 }}>
          <Typography gutterBottom variant="h6">
            Cores primárias
          </Typography>

          <Stack direction="row" justifyContent="space-evenly" p={2} alignItems="center">
            <RHFSketchPicker control={control} name="colors[0]" disableAlpha presetColors={[]} />

            {!!primaryColor.hex && (
              <Stack sx={{ width: '30px', height: '150px' }}>
                <Box height="20%" sx={{ bgcolor: lighten(primaryColor.hex, 0.65) }} />
                <Box height="20%" sx={{ bgcolor: lighten(primaryColor.hex, 0.25) }} />
                <Box height="20%" sx={{ bgcolor: primaryColor.hex }} />
                <Box height="20%" sx={{ bgcolor: darken(primaryColor.hex, 0.25) }} />
                <Box height="20%" sx={{ bgcolor: darken(primaryColor.hex, 0.65) }} />
              </Stack>
            )}
          </Stack>
        </Card>

        <Card sx={{ p: 3 }}>
          <Typography gutterBottom variant="h6">
            Cores secundárias
          </Typography>

          <Stack direction="row" justifyContent="space-evenly" p={2} alignItems="center">
            <RHFSketchPicker control={control} name="colors[1]" disableAlpha presetColors={[]} />

            {!!secondaryColor.hex && (
              <Stack sx={{ width: '30px', height: '150px' }}>
                <Box height="20%" sx={{ bgcolor: lighten(secondaryColor.hex, 0.65) }} />
                <Box height="20%" sx={{ bgcolor: lighten(secondaryColor.hex, 0.25) }} />
                <Box height="20%" sx={{ bgcolor: secondaryColor.hex }} />
                <Box height="20%" sx={{ bgcolor: darken(secondaryColor.hex, 0.25) }} />
                <Box height="20%" sx={{ bgcolor: darken(secondaryColor.hex, 0.65) }} />
              </Stack>
            )}
          </Stack>
        </Card>
      </Box>

      <Card sx={{ p: 3 }}>
        <Typography variant="h3" gutterBottom>
          Landing page (visão alunos/jovens)
        </Typography>

        <Typography variant="h6" gutterBottom>
          Preview
        </Typography>
        <LPPreview
          logo={isString(logo) ? logo : logo ? URL.createObjectURL(logo) : ''}
          title={toUser.title}
          subtitle={toUser.subtitle}
          photo={isString(photoUser) ? photoUser : photoUser ? URL.createObjectURL(photoUser) : ''}
          colors={[primaryColor, secondaryColor]}
        />

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          Foto da LP
        </Typography>
        <RHFUploadSingleFile
          control={control}
          name="photoUser"
          accept={{ 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }}
          maxSize={3000000}
          onDrop={(files) => handleDrop(files, 'photoUser')}
          helperText={
            <Typography textAlign="center" color="text.secondary" my={2}>
              Permitido apenas *.jpeg, *.jpg, *.png, *.gif <br /> Tamanho máximo de 3 mb
              <br /> Proporção de 501 x 415
            </Typography>
          }
          disabled={isSubmitting}
          sx={{ gridColumn: { sm: 'span 3', md: 'unset' } }}
        />
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: '0.5fr 1.5fr',
            p: 2
          }}>
          <Typography variant="h6">H1</Typography>
          <RHFTextField
            control={control}
            name="toUser.title"
            disabled={isSubmitting}
            multiline
            minRows={10}
            label="H1"
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: '0.5fr 1.5fr',
            p: 2
          }}>
          <Typography variant="h6">H2</Typography>
          <RHFTextField
            control={control}
            name="toUser.subtitle"
            disabled={isSubmitting}
            multiline
            minRows={10}
            label="H2"
          />
        </Box>
      </Card>

      <Card sx={{ p: 3 }}>
        <Typography variant="h4">Landing page (visão empresa)</Typography>
        <Typography variant="h6" gutterBottom>
          Preview
        </Typography>
        <LPPreview
          logo={isString(logo) ? logo : logo ? URL.createObjectURL(logo) : ''}
          title={toCompany.title}
          subtitle={toCompany.subtitle}
          photo={
            isString(photoCompany)
              ? photoCompany
              : photoCompany
              ? URL.createObjectURL(photoCompany)
              : ''
          }
          colors={[primaryColor, secondaryColor]}
        />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Foto da LP</Typography>
        <RHFUploadSingleFile
          control={control}
          name="photoCompany"
          accept={{ 'image/*': ['.jpeg', '.jpg', '.png', '.gif'] }}
          maxSize={3000000}
          onDrop={(files) => handleDrop(files, 'photoCompany')}
          helperText={
            <Typography textAlign="center" color="text.secondary" my={2}>
              Permitido apenas *.jpeg, *.jpg, *.png, *.gif <br /> Tamanho máximo de 3 mb <br />{' '}
              Proporção de 501 x 415
            </Typography>
          }
          disabled={isSubmitting}
          sx={{ gridColumn: { sm: 'span 3', md: 'unset' } }}
        />
        <Divider sx={{ my: 2 }} />

        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: '0.5fr 1.5fr',
            p: 2
          }}>
          <Typography variant="h6">H1</Typography>
          <RHFTextField
            control={control}
            name="toCompany.title"
            disabled={isSubmitting}
            multiline
            minRows={10}
            label="H1"
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: '0.5fr 1.5fr',
            p: 2
          }}>
          <Typography variant="h6">H2</Typography>
          <RHFTextField
            control={control}
            name="toCompany.subtitle"
            disabled={isSubmitting}
            multiline
            minRows={10}
            label="H2"
          />
        </Box>
      </Card>

      {!!errors?.afterSubmit?.message && (
        <FormHelperText error sx={{ alignSelf: 'flex-end' }}>
          {errors.afterSubmit.message}
        </FormHelperText>
      )}

      <LoadingButton
        loading={isSubmitting}
        size="large"
        variant="contained"
        onClick={handleSubmit(submit, (err) => console.log(err))}
        sx={{ alignSelf: 'flex-end' }}>
        Salvar alterações
      </LoadingButton>
    </Stack>
  );
}

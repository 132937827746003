import { useState } from 'react';

import { Controller } from 'react-hook-form';

import { Autocomplete, Checkbox, Chip, List, TextField, createFilterOptions } from '@mui/material';
import { styled } from '@mui/material/styles';

export function RHFMultiSelect({
  name,
  label,
  options,
  control,
  onChangeSideEffect = (v) => {},
  moreTextFieldProps = (params) => params,
  rules,
  ...other
}) {
  const [inputValue, setInputValue] = useState('');

  const Listbox = styled(List)(({ theme }) => ({
    li: {
      '&:selected': {
        backgroundColor: 'secondary'
      },
      backgroundColor: 'info'
    }
  }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple
          ListboxComponent={Listbox}
          onChange={(_, newValue) => {
            field.onChange(newValue);
            onChangeSideEffect(newValue);
          }}
          options={options}
          isOptionEqualToValue={(option, value) =>
            typeof option === 'string' || typeof value === 'string'
              ? option === value
              : option.value === value.value
          }
          getOptionLabel={(option) => (typeof option === 'string' ? option : option?.label || '')}
          disableCloseOnSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={typeof option === 'string' ? option : option.value}
                label={typeof option === 'string' ? option : option.label}
                color="primary"
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ p: 0, mr: 2 }} size="small" checked={selected} />
              {typeof option === 'string' ? option : option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
              {...moreTextFieldProps(params)}
            />
          )}
          // persistent input
          inputValue={inputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('');
            } else if (reason !== 'reset') {
              setInputValue(value);
            }
          }}
          {...other}
        />
      )}
    />
  );
}

const filter = createFilterOptions();

export function RHFFreeSoloMultiSelect({
  name,
  label,
  options,
  control,
  onChangeSideEffect = () => {},
  moreTextFieldProps = (params) => params,
  ...other
}) {
  const Listbox = styled(List)(() => ({
    li: {
      '&:selected': {
        backgroundColor: 'secondary'
      },
      backgroundColor: 'info'
    }
  }));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple
          ListboxComponent={Listbox}
          onChange={(_, newValue) => {
            field.onChange(newValue);
            onChangeSideEffect();
          }}
          freeSolo
          options={options}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option || ''}
          disableCloseOnSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={option}
                label={option}
                color="primary"
              />
            ))
          }
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            const isExisting = options.some((op) => inputValue === op);

            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue);
            }

            return filtered;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ p: 0, mr: 2 }} size="small" checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
              {...moreTextFieldProps(params)}
            />
          )}
          {...other}
        />
      )}
    />
  );
}

import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading } from '../components/loading';
import { authenticationService } from '../services/authentication';
import { profilePictureURL } from '../utils/helpers';

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [authorization, setAuthorization] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('auth.token');

    if (!token) {
      return setIsLoading(false);
    }

    authenticationService.isAuthenticated().then((res) => {
      if (!res.isError) {
        const { authorization } = res.data;
        const { user } = authorization;
        authorization.user.name = `${user.firstName} ${user.lastName}`;
        setAuthorization(authorization);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(authorization).length) {
      const { user } = authorization;
      if (user.firstName && !user.photoURL) {
        setAuthorization((prev) => ({
          ...prev,
          user: { ...prev.user, photoURL: profilePictureURL(prev.user) }
        }));
      }
    }
  }, [authorization]);

  const authenticate = useCallback(async (email, password) => {
    const res = await authenticationService.authenticate(email, password);
    if (!res.isError) {
      const { authorization, token } = res.data;
      const { user } = authorization;

      localStorage.setItem('auth.token', token);
      authorization.user.name = `${user.firstName} ${user.lastName}`;

      setAuthorization(authorization);
    }
    return res;
  }, []);

  const logout = useCallback(async () => {
    const res = await authenticationService.logout();
    if (res.status === 200) {
      setAuthorization({});
      localStorage.removeItem('auth.token');
    }
  }, []);

  const value = useMemo(
    () => ({ authorization, authenticate, isLoading, logout }),
    [authorization, isLoading]
  );

  if (isLoading) {
    return <Loading />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

import { useEffect, useState } from 'react';

import { Box, Card, Stack, Typography } from '@mui/material';

import { upperFirst } from 'lodash';
import { useWatch } from 'react-hook-form';
import {
  RHFMultiSelect,
  RHFPatternTextField,
  RHFSelect,
  RHFTextField
} from '../../../components/hook-form';

import { PartnersService } from '../../../services/partners';
import Countries from '../../../utils/countries.json';

const companySizes = [
  { value: 'A', label: '1 colaborador (empreendedor individual ou autônomo)' },
  { value: 'B', label: '2-10 colaboradores' },
  { value: 'C', label: '11-50 colaboradores' },
  { value: 'D', label: '51-200 colaboradores' },
  { value: 'E', label: '201-500 colaboradores' },
  { value: 'F', label: '501-1000 colaboradores' },
  { value: 'G', label: '1001-5000 colaboradores' },
  { value: 'H', label: '5001-10.000 colaboradores' },
  { value: 'I', label: '10.001+ colaboradores' }
];

export function BasicDataForm({ methods }) {
  const {
    formState: { isSubmitting },
    control
  } = methods;

  const ddi = useWatch({ name: 'ddi', control });

  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    PartnersService.getCompanyIndustries()
      .then((res) => {
        setIndustries(
          res.data.data.map((industry) => ({
            label: upperFirst(industry),
            value: industry
          }))
        );
      })
      .catch(() => []);
  }, []);

  return (
    <Card sx={{ p: 3 }}>
      <Stack gap={2}>
        <Typography variant="h6">Dados básicos</Typography>
        <RHFTextField
          control={control}
          name="corporateName"
          disabled={isSubmitting}
          label="Razão social da empresa"
        />

        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: '1fr', sm: ' 2fr 1fr 2fr' }
          }}>
          <RHFTextField
            control={control}
            name="email"
            disabled={isSubmitting}
            label="E-mail"
            sx={{ gridColumn: { sm: 'span 3', md: 'unset' } }}
          />

          <RHFSelect
            control={control}
            name="ddi"
            disabled={isSubmitting}
            options={Countries.map((c) => ({
              value: c.ddi,
              label: `+${Number(c.ddi)} — ${c.country}`
            }))}
            label="Código do país"
          />

          {ddi === '0055' ? (
            <RHFPatternTextField
              control={control}
              name="phone"
              type="tel"
              disabled={isSubmitting}
              label="Telefone da empresa"
              sx={{ gridColumn: { sm: 'span 2', md: 'unset' } }}
              format="(##) #####-####"
              mask="_"
              valueIsNumericString
            />
          ) : (
            <RHFTextField
              control={control}
              name="phone"
              type="tel"
              disabled={isSubmitting}
              label="Telefone da empresa"
              sx={{ gridColumn: { sm: 'span 2', md: 'unset' } }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }
          }}>
          <RHFTextField
            control={control}
            name="commercialName"
            disabled={isSubmitting}
            label="Nome fantasia"
          />

          <RHFPatternTextField
            control={control}
            name="cnpj"
            disabled={isSubmitting}
            label="CNPJ"
            format="##.###.###/####-##"
            mask="_"
            valueIsNumericString
          />

          <RHFSelect
            control={control}
            name="companySize"
            disabled={isSubmitting}
            label="Tamanho da empresa"
            options={companySizes}
          />

          <RHFMultiSelect
            control={control}
            name="industries"
            disabled={isSubmitting}
            label="Setores da empresa"
            options={industries}
            limitTags={2}
          />
        </Box>
      </Stack>
    </Card>
  );
}

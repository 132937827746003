import { api } from '..';
import { AUTH } from '../routes';

class AuthenticationService {
  async isAuthenticated() {
    const response = await api.get(AUTH.CHECK_AUTHENTICATION);
    return makeResponse(response);
  }

  async authenticate(email, password) {
    const response = await api.post(AUTH.AUTHENTICATE, { email, password });
    return makeResponse(response);
  }

  async logout() {
    const response = await api.get(AUTH.LOGOUT);
    return response;
  }
}

function makeResponse(response) {
  return {
    isError: Math.floor(response.status / 100) !== 2,
    data: response.data
  };
}

export const authenticationService = new AuthenticationService();

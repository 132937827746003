import { Box, CircularProgress, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
import Modal from '../../components/Modal';
import { useToast } from '../../hooks/useToast';
import { HigherEducationsService } from '../../services/HigherEducations';
import { fData } from '../../utils/formatNumber';
import defaultInstitutionLogo from './defaultInstitutionLogo.png';

export default function AddUpdateModal({ increment, open, setOpen, editMode, data }) {
  const [newLogo, setNewLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const apiRef = useGridApiContext();
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      institution: editMode ? data.institution : '',
      acronym: editMode ? data.acronym : '',
      logoUrl: editMode ? data.universityURL : defaultInstitutionLogo
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setNewLogo(true);
        setValue(
          'logoUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        );
      }
    },
    [setValue]
  );

  function submitInstitution() {
    setLoading(true);
    handleSubmit((res) => {
      // se não há informações adicionadas nos campos de instituição ou acrônimo, o código para neste if e mostra um toast com erro
      if (!res.acronym || !res.institution) {
        setLoading(false);
        toast.error('O nome da instituição e o nome simples devem ser preenchidos');
      } else {
        // função de criação de instituição, só será executada se receber o type 'add' pela chamada do componente pai.
        if (!editMode) {
          addInstitution(res);
        }
        // função de edição de instituição, só será executada se receber o type 'edit' pela chamada do componente pai
        if (editMode) {
          updateInstitution(res);
        }
      }
    })();
  }

  async function addInstitution(res) {
    const createResponse = await HigherEducationsService.createUniversities({
      institution: res.institution,
      acronym: res.acronym
    });
    // se a requisição acima retornar em erro, este switch traduzirá a mensagem que vem do backend.
    if (createResponse.isError) {
      setLoading(false);
      return switchError(createResponse);
    }
    if (newLogo) {
      await addUpdateLogo(res, createResponse);
    } else {
      apiRef.current.updateRows([createResponse.data.data]);
    }
    toast.success(`Instituição "${res.institution}" adicionada!`);
    increment();
    return handleClose();
  }

  async function updateInstitution(res) {
    // se as informações adicionadas nos campos forem exatamente iguais aos presentes no backend,
    // o código para neste if e mostra um toast informativo e **não faz** requisição no back-end
    if (
      res.acronym === data.acronym &&
      res.institution === data.institution &&
      res.logoUrl === data.universityURL
    ) {
      setLoading(false);
      return toast.info('As informações inseridas são iguais as que constam no banco de dados.');
    }
    const updateResponse = await HigherEducationsService.updateUniversities({
      id: data.id,
      institution: res.institution,
      acronym: res.acronym
    });
    if (newLogo) {
      addUpdateLogo(res);
    }
    // se a requisição acima retornar em erro, este switch traduzirá a mensagem que vem do backend.
    if (updateResponse.isError) {
      setLoading(false);
      return switchError(updateResponse);
    }
    if (res.acronym !== data.acronym || res.institution !== data.institution) {
      toast.success(`Instituição "${res.institution}" foi atualizada!`);
    }
    apiRef.current.updateRows([updateResponse.data.data]);
    return handleClose();
  }

  async function addUpdateLogo(res, createResponse) {
    const logo = new FormData();
    logo.append('logo', res.logoUrl);
    const logoResponse = await HigherEducationsService.createUniversityLogo({
      id: createResponse.data.data.id ?? data.id,
      logo
    });
    if (logoResponse.isError) {
      return toast.error(logoResponse.data.message);
    }
    // esse toast só aparece se o acrônimo e a instituição estiverem iguais ao backend, mas o logo for diferente.
    if (
      editMode &&
      res.acronym === data.acronym &&
      res.institution === data.institution &&
      res.logoUrl !== data.universityURL
    ) {
      toast.success(`Logo da instituição ${data.institution} atualizado!`);
    }
    setNewLogo(false);
    return apiRef.current.updateRows([
      { ...logoResponse.data.data, logoUrl: logoResponse.data.data.universityURL }
    ]);
  }

  function switchError(res) {
    switch (res.data.message) {
      case 'this institution already exists in the internal records':
        return toast.error('Já existe uma instituição com este nome.');
      case 'this acronym already exists in the internal records':
        return toast.error('Já existe uma instituição com este acrônimo.');
      default:
        return toast.error('Erro desconhecido. Contate a equipe de tecnologia.');
    }
  }

  function handleClose() {
    if (!editMode) {
      reset({ acronym: '', institution: '', logoUrl: defaultInstitutionLogo });
    }
    setLoading(false);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      close={() => {
        handleClose();
      }}
      title={editMode ? `Editar ${data.institution}` : 'Adicionar instituição de ensino'}
      closeButtonText="Cancelar"
      disableButton={loading}
      submitButtonText={
        <>
          {editMode ? 'Atualizar instituição' : 'Criar instituição'}{' '}
          {loading && (
            <CircularProgress
              size="0.75em"
              thickness={7}
              disableShrink
              sx={{ ml: 0.75, color: 'inherit' }}
            />
          )}
        </>
      }
      submitButtonAction={() => submitInstitution()}>
      <DialogContent>
        <DialogContentText fontWeight={500} color="#000">
          Sobre a Instituição
        </DialogContentText>
        <RHFTextField
          autoFocus
          fullWidth
          name="institution"
          control={control}
          type="text"
          margin="dense"
          variant="outlined"
          label="Nome da instituição de ensino"
        />
        <RHFTextField
          fullWidth
          name="acronym"
          control={control}
          type="text"
          margin="dense"
          variant="outlined"
          label="Nome simples / acrônimo (ex. USP)"
        />
      </DialogContent>
      <DialogContent>
        <Box p={2} border="1px solid #e4e7ea" borderRadius={1}>
          <DialogContentText fontWeight={500} color="#000">
            Logo da instituição
          </DialogContentText>
          <Box sx={{ mb: 5 }}>
            <RHFUploadAvatar
              name="logoUrl"
              accept="image/*"
              maxSize={3145728}
              onDrop={handleDrop}
              control={control}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary'
                  }}>
                  {!newLogo && (
                    <>
                      <strong>Imagem padrão definida</strong>
                      <br />
                      <br />
                    </>
                  )}
                  Permitido *.jpeg, *.jpg, *.png.
                  <br />
                  Tamanho máximo de {fData(3145728)}
                </Typography>
              }
            />
          </Box>
        </Box>
      </DialogContent>
    </Modal>
  );
}

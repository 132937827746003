import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, FormHelperText, Stack, Typography } from '@mui/material';
import { upperFirst } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { RHFMultiDebounceCombobox, RHFMultiSelect } from '../../../components/hook-form';
import { HigherEducationsService } from '../../../services/HigherEducations';
import { CoursesService } from '../../../services/courses';
import { PartnersService } from '../../../services/partners';

const GRADUATION_TYPES = [
  { value: 'curso livre', label: 'Curso livre' },
  { value: 'ensino médio', label: 'Ensino médio' },
  { value: 'ensino técnico', label: 'Ensino técnico' },
  { value: 'ensino superior', label: 'Ensino superior' },
  { value: 'pós-graduação', label: 'Pós-graduação' }
];

const schema = yup.object({
  types: yup.array().of(
    yup.object({
      label: yup.string(),
      value: yup.string()
    })
  ),
  institutions: yup.array().of(
    yup.object({
      label: yup.string(),
      value: yup.string()
    })
  ),
  courses: yup.array().of(
    yup.object({
      label: yup.string(),
      value: yup.string()
    })
  )
});

export function PreCompatibilityForm({ companyId, getNextStep }) {
  const [searchParams] = useSearchParams();
  const [count, setCount] = useState(-1);
  const [countFilters, setCountFilters] = useState({});

  const {
    formState: { isSubmitting, errors },
    control,
    reset,
    setError,
    handleSubmit
  } = useForm({
    defaultValues: {
      types: [],
      institutions: [],
      courses: [],
      afterSubmit: ''
    },
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const companyId = searchParams.get('company') || '';

    if (companyId) {
      PartnersService.getPreCompatibility(companyId).then(async (res) => {
        const data = res.data.data.preCompatibility.graduation;

        setCountFilters({
          graduationTypeQuery: data.types.join(','),
          institutionsQuery: data.institutions.map((inst) => inst._id).join(','),
          coursesQuery: data.courses.map((course) => course._id).join(',')
        });

        reset({
          types: data.types.map((type) => ({ value: type, label: upperFirst(type) })) || [],
          institutions:
            data.institutions.map((inst) => ({ value: inst._id, label: inst.name })) || [],
          courses: data.courses.map((course) => ({ label: course.name, value: course._id })) || []
        });
      });
    }
  }, [searchParams, reset]);

  const submit = async (data) => {
    try {
      const formatted = {
        preCompatibility: {
          graduation: {
            institutions: data.institutions.map(({ value }) => value),
            courses: data.courses.map(({ value }) => value),
            types: data.types.map(({ value }) => value)
          }
        }
      };

      const res = await PartnersService.setPreCompatibility(companyId, formatted);
      if (res.isError) throw new Error();

      getNextStep();
    } catch {
      setError('afterSubmit', {
        message: 'Houve um erro ao definir a pré-compatibilidade. Por favor tente novamente.'
      });
    }

    reset(data, { keepErrors: true });
  };

  useEffect(() => {
    PartnersService.getCompatibilityCount(countFilters).then((res) => {
      setCount(res?.data?.data?.countResult ?? -1);
    });
  }, [countFilters]);

  return (
    <Stack gap={3}>
      <Card sx={{ p: 3 }}>
        <Stack gap={2} sx={{ width: '100%' }}>
          <Typography variant="h6">Pré-compatibilidade</Typography>

          <Typography variant="subtitle1">Segmentação do público</Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 5fr',
              gap: 2,
              bgcolor: 'background.neutral',
              borderRadius: 2,
              p: 3
            }}>
            <Typography variant="subtitle2">Formação acadêmica</Typography>
            <Stack
              gap={2}
              sx={{ bgcolor: 'background.default', borderRadius: 2, py: 3, px: 4, width: '100%' }}>
              <Typography variant="overline">Tipo de curso</Typography>
              <RHFMultiSelect
                options={GRADUATION_TYPES}
                disabled={isSubmitting}
                label="Tipo do curso"
                control={control}
                name="types"
                onChangeSideEffect={(v) => {
                  setCountFilters((prev) => ({
                    ...prev,
                    graduationTypeQuery: v?.length
                      ? v.map((item) => item.value).join(',')
                      : undefined
                  }));
                }}
              />
              <Typography variant="overline">Curso</Typography>
              <RHFMultiDebounceCombobox
                disabled={isSubmitting}
                label="Cursos"
                control={control}
                name="courses"
                getOptions={(query) =>
                  CoursesService.getCourses({ course: query })
                    .then((res) =>
                      res.data.data.map((c) => ({
                        label: c.course,
                        value: c.id
                      }))
                    )
                    .catch(() => [])
                }
                onChangeSideEffect={(v) => {
                  setCountFilters((prev) => ({
                    ...prev,
                    coursesQuery: v?.length ? v.map((course) => course.value).join(',') : undefined
                  }));
                }}
              />
              <Typography variant="overline">Instituições de ensino</Typography>
              <RHFMultiDebounceCombobox
                disabled={isSubmitting}
                label="Instituições de ensino"
                control={control}
                name="institutions"
                getOptions={(query) =>
                  HigherEducationsService.getInstitutions({ institution: query })
                    .then((res) =>
                      res.data.data.map((inst) => ({
                        label: inst.institution,
                        value: inst.id
                      }))
                    )
                    .catch(() => [])
                }
                onChangeSideEffect={(v) => {
                  setCountFilters((prev) => ({
                    ...prev,
                    institutionsQuery: v?.length ? v.map((inst) => inst.value).join(',') : undefined
                  }));
                }}
              />
            </Stack>
          </Box>

          {count >= 0 && (
            <Stack sx={{ bgcolor: 'background.neutral', borderRadius: 0.5, p: 3 }} gap={3}>
              <Typography variant="subtitle1">
                Tamanho do público alvo:{' '}
                <Typography variant="overline" fontWeight="bold">
                  {count}
                </Typography>
              </Typography>
            </Stack>
          )}
          {errors?.afterSubmit?.message && (
            <FormHelperText error>{errors.afterSubmit.message}</FormHelperText>
          )}
        </Stack>
      </Card>
      <LoadingButton
        variant="contained"
        onClick={handleSubmit(submit, (err) => console.log(err))}
        loading={isSubmitting}
        size="large"
        sx={{ alignSelf: 'flex-end' }}>
        Salvar
      </LoadingButton>
    </Stack>
  );
}

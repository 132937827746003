import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';

export function useToast() {
  const theme = useTheme();

  const options = useMemo(() => ({ theme: theme.palette.mode }), [theme.palette.mode]);

  const info = useCallback(
    (infoMessage, customOptions) => toast.info(infoMessage, { ...options, ...customOptions }),
    [options]
  );

  const success = useCallback(
    (message, customOptions) => toast.success(message, { ...options, ...customOptions }),
    [options]
  );

  const error = useCallback(
    (errorMessage, customOptions) => toast.error(errorMessage, { ...options, ...customOptions }),
    [options]
  );

  const warn = useCallback(
    (warnMessage, customOptions) => toast.warn(warnMessage, { ...options, ...customOptions }),
    [options]
  );

  return { info, success, error, warn };
}

import { LoadingButton } from '@mui/lab';
import { Card, Modal, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { capitalCase } from 'change-case';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useObjectState } from '../../../hooks/useObjectState';
import { useToast } from '../../../hooks/useToast';
import { managementService } from '../../../services/management';
import Label from '../../Label';

EditStaffMemberModal.propTypes = {
  staffMember: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default function EditStaffMemberModal(props) {
  const [tags, updateTags] = useObjectState({ ...props.staffMember.authority });
  const { authority } = useAuth();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    setLoading(true);
    const payload = { _id: props.staffMember._id, authority: tags };
    const res = await managementService.updateStaffMemberAuthority(payload);
    if (res.isError) {
      toast.error(res.data.message);
      setLoading(false);
    }
    toast.success(`Permissões de "${props.staffMember.user.name}" atualizadas`);
    setLoading(false);
    props.onSuccess();
  };

  function handleSelectLabel(tagName) {
    return () => {
      updateTags(tagName, !tags[tagName]);
    };
  }

  function formatTagName(tagName) {
    return tagName.replace(/is/, '');
  }

  const TAG_LIST = useMemo(
    () => [
      'isAdmin',
      'isDeveloper',
      'isContentManager',
      'isOperationsManager',
      'isFinancialManager'
    ],
    [authority]
  );

  const isUntouched = useMemo(
    () => Object.entries(tags).every(([key, value]) => value === props.staffMember.authority[key]),
    [tags]
  );

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card
        sx={{
          width: 'fit-content',
          outline: 'none',
          padding: '2rem',
          maxWidth: '500px'
        }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Editar membro do time
        </Typography>

        <Stack my={2}>
          <Box sx={{ backgroundColor: 'background.default', borderRadius: 1, padding: 1 }}>
            <Typography fontWeight="bold">{props.staffMember.user.name}</Typography>
            <Typography fontWeight="bold">{props.staffMember.user.email}</Typography>
          </Box>
        </Stack>

        <Stack mt={2} alignItems="center" gap={2}>
          <Typography>Permissões disponíveis</Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {TAG_LIST.map((tagName) => (
              <Label
                key={`label_${tagName}`}
                sx={{ cursor: 'pointer', userSelect: false, transition: '100ms' }}
                onClick={handleSelectLabel(tagName)}
                color={tags[tagName] ? 'primary' : 'default'}>
                {capitalCase(formatTagName(tagName))}
              </Label>
            ))}
          </Box>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            onClick={handleSubmit}
            disabled={isUntouched}>
            Salvar alterações
          </LoadingButton>
        </Stack>
      </Card>
    </Modal>
  );
}

import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, IconButton } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  // GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import { useObjectState } from '../../hooks/useObjectState';
import AddUpdateModal from './addUpdateModal';

export function CustomToolbar({ addTotalNumber, refresh }) {
  const [modal, updateModal] = useObjectState({ addInstitution: false });

  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/* <GridToolbarExport /> */}
        <IconButton size="small" color="primary" onClick={refresh}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Button
        onClick={() => updateModal('addInstitution', true)}
        startIcon={<Icon icon={plusFill} />}>
        Criar instituição
      </Button>

      <AddUpdateModal
        increment={addTotalNumber}
        open={modal.addInstitution}
        setOpen={() => updateModal('addInstitution', false)}
      />
    </GridToolbarContainer>
  );
}

import laptop from '@iconify/icons-bx/laptop';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import adminOutlined from '@iconify/icons-ic/baseline-admin-panel-settings';
import baselineBusinessCenter from '@iconify/icons-ic/baseline-business-center';
import briefcaseAccount from '@iconify/icons-mdi/briefcase-account';
import { Icon } from '@iconify/react';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
    disabled: false
  },
  {
    title: 'Staff',
    path: '/dashboard/staff',
    icon: getIcon(adminOutlined),
    disabled: false
  },
  {
    title: 'Usuários',
    path: '/dashboard/users',
    icon: getIcon(peopleFill),
    disabled: false
  },
  {
    title: 'Empresas',
    path: '/dashboard/companies',
    icon: getIcon(baselineBusinessCenter),
    disabled: false
  },
  {
    title: 'Processos',
    path: '/dashboard/processes',
    icon: getIcon(laptop),
    disabled: false
  },
  {
    title: 'Cursos',
    path: '/dashboard/courses',
    icon: getIcon('mdi:book-open'),
    disabled: false
  },
  {
    title: 'Instituições',
    path: '/dashboard/institutions',
    icon: getIcon(homeFill),
    disabled: false
  },
  {
    title: 'Agências',
    icon: getIcon(briefcaseAccount),
    disabled: false,
    children: [
      {
        title: 'Todas agências',
        path: '/dashboard/agencies/list',
        disabled: false
      },
      {
        title: 'Criar agência',
        path: '/dashboard/agencies/create',
        disabled: false
      }
    ]
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: getIcon(fileTextFill),
    disabled: true
  },
  {
    title: 'login',
    path: '/login',
    icon: getIcon(lockFill),
    disabled: true
  }
];

export default sidebarConfig;

import {
  Autocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  MenuItem,
  TextField,
  createFilterOptions
} from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDebouncerEffect } from '../../hooks/useDebouncerEffect';
// ----------------------------------------------------------------------

export function RHFSelect({ name, options, control, onChangeSideEffect = () => {}, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          select
          error={!!error}
          helperText={error?.message}
          onChange={(e) => {
            field.onChange(e.target.value);
            onChangeSideEffect(e.target.value);
          }}
          {...rest}>
          {options.map((op, index) => (
            <MenuItem key={index} value={op.value}>
              {op.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}

export function RHFCombobox({ name, label, options, control, textFieldProps, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(_, newValue) => field.onChange(newValue)}
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
              {...textFieldProps}
            />
          )}
          {...rest}
        />
      )}
    />
  );
}

export function RHFMultiDebounceCombobox({
  name,
  label,
  control,
  getOptions,
  debounceTime = 500,
  isInputEqualToOption,
  freeSolo = false,
  onChangeSideEffect = () => {},
  ...rest
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useDebouncerEffect(
    async () => {
      let isMounted = true;
      if (searchQuery) {
        if (isMounted) setLoading(true);
        await getOptions(searchQuery).then((res) => {
          if (isMounted) {
            setSearchResults(res);
            setLoading(false);
          }
        });
      }
      return () => (isMounted = false);
    },
    [searchQuery],
    debounceTime
  );

  const filter = createFilterOptions();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(_, newValue) => {
            field.onChange(newValue);
            onChangeSideEffect(newValue);
          }}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setSearchQuery('');
            } else if (reason !== 'reset') {
              setSearchQuery(value);
            }
          }}
          isOptionEqualToValue={(option, value) =>
            typeof option === 'string' || typeof value === 'string'
              ? option === value
              : option.value === value.value
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={typeof option === 'string' ? option : option.value}
                label={typeof option === 'string' ? option : option.label}
                color="primary"
              />
            ))
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox sx={{ p: 0, mr: 2 }} size="small" checked={selected} />
              {typeof option === 'string' ? option : option.label}
            </li>
          )}
          inputValue={searchQuery}
          options={searchResults}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (freeSolo) {
              const { inputValue } = params;

              const isExisting = options.some((option) =>
                !isInputEqualToOption
                  ? inputValue.toLowerCase() === option.label.toLowerCase()
                  : isInputEqualToOption(option.label, inputValue)
              );

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  label: `Adicionar "${inputValue}"`
                });
              }
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          autoHighlight
          handleHomeEndKeys
          disablePortal
          multiple
          disableCloseOnSelect
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.label;
          }}
          freeSolo={freeSolo}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error?.message}
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="primary" disableShrink thickness={6} size={20} />
                    ) : null}
                    {params.InputProps?.endAdornment}
                  </>
                )
              }}
            />
          )}
          {...rest}
        />
      )}
    />
  );
}

import { CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import Modal from '../../components/Modal';
import { useToast } from '../../hooks/useToast';
import { CoursesService } from '../../services/courses';

export function CreateNewCourseModal({ open, close, updateDataGrid, incrementPageTotal }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();

  const createNewCourse = async () => {
    setLoading(true);

    CoursesService.registerNewCourse(name).then((res) => {
      setLoading(false);

      if (res.status === 400) {
        setError('Houve um erro na criação desse curso. Verifique se ele já não existe.');
      } else {
        const { data } = res.data;
        updateDataGrid([data]);
        incrementPageTotal();
        toast.success(`O curso "${name}" foi criado com sucesso!`);
        close();
      }
    });
  };

  return (
    <Modal
      open={open}
      close={close}
      title="Criar curso"
      closeButtonText="Cancelar"
      submitButtonText={
        <>
          Criar curso{' '}
          {loading && (
            <CircularProgress
              size="0.75em"
              thickness={7}
              disableShrink
              sx={{ ml: 0.75, color: 'inherit' }}
            />
          )}
        </>
      }
      submitButtonAction={createNewCourse}>
      <TextField
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setError('');
        }}
        label="Nome do curso"
        fullWidth
        error={!!error}
        helperText={error}
      />
    </Modal>
  );
}

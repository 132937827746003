import { api } from '..';
import { COMPANY } from '../routes';

export class CompanyService {
  /**
   * @param {{ page:number, limit:number, orderBy: string, ascending: boolean }} params
   * @returns
   */
  static async getPaginated(params) {
    const res = await api.get(COMPANY.PAGINATED, { params });
    return res;
  }

  static async getCompanies(params) {
    const res = await api.get(`/companies/getCompanies`, { params });
    return res;
  }
}

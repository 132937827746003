import { Box, Button, Stack, Typography } from '@mui/material';
import SvgIconStyle from '../../../components/SvgIconStyle';

export function LPPreview({ logo, photo, title, subtitle, colors }) {
  return (
    <Stack
      alignItems="flex-start"
      sx={{ border: '1px solid', borderColor: 'text.secondary', p: 2, borderRadius: 1 }}
      gap={5}>
      <img src={logo} style={{ height: 40, objectFit: 'contain' }} alt="Logo" />

      <Stack direction="row" gap={4}>
        <Stack gap={2}>
          <Typography variant="subtitle1" sx={{ color: colors[0].hex, textTransform: 'uppercase' }}>
            plataforma exclusiva
          </Typography>

          <Typography variant="h1" sx={{ fontSize: '2rem !important' }}>
            {title || 'H1'}
          </Typography>

          <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1rem !important' }}>
            {subtitle || 'H2'}
          </Typography>

          <Button sx={{ bgcolor: colors[0].hex, my: 2 }} variant="contained" size="large">
            Acessar plataforma
          </Button>

          <Stack direction="row" gap={2}>
            <Stack direction="row" gap={1} alignItems="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ bgcolor: colors[0].hex, p: 1, borderRadius: '100%', height: 32, width: 32 }}>
                <SvgIconStyle
                  src="/static/icons/ic_user_group.svg"
                  sx={{ color: '#fff', display: 'block' }}
                />
              </Stack>
              <Typography variant="subtitle1">Comunidades</Typography>
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ bgcolor: colors[0].hex, p: 1, borderRadius: '100%', height: 32, width: 32 }}>
                <SvgIconStyle
                  src="/static/icons/ic_booking.svg"
                  sx={{ color: '#fff', display: 'block' }}
                />
              </Stack>
              <Typography variant="subtitle1">Eventos</Typography>
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ bgcolor: colors[0].hex, p: 1, borderRadius: '100%', height: 32, width: 32 }}>
                <SvgIconStyle
                  src="/static/icons/ic_job.svg"
                  sx={{ color: '#fff', display: 'block' }}
                />
              </Stack>
              <Typography variant="subtitle1">Oportunidades</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Box sx={{ borderRadius: 3, m: 'auto' }}>
          <Box
            component="img"
            src={photo}
            sx={{
              aspectRatio: '501 / 415',
              objectFit: 'contain',
              borderRadius: 3
            }}
            alt="Imagem"
          />
        </Box>
      </Stack>
    </Stack>
  );
}

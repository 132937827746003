import { useCallback, useRef, useState } from 'react';
import { useCleanupEffect } from './useCleanupEffect';
import { useToast } from './useToast';

export function useFilteredPagination({
  serviceMethod,
  initialPageSize = 10,
  initialSortingOrder = 'desc',
  initialOrderBy = 'createdAt',
  initialFilter = {}
}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [filter, setFilter] = useState(initialFilter);
  const [sortingOrder, setSortingOrder] = useState(initialSortingOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [loading, setLoading] = useState(true);
  const timeout = useRef(null);
  const toast = useToast();

  const debouncerTimeout = 300;

  useCleanupEffect(
    (state) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setLoading(true);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        fetchPages({ page, pageSize, filter, orderBy, sortingOrder }).then((res) => {
          if (res.isError) {
            setData([]);
            setTotal(0);
            toast.error('Nenhum resultado encontrado.', {
              autoClose: false,
              hideProgressBar: true,
              progress: undefined,
              closeButton: true
            });
          } else {
            const { data, total } = res.data;
            if (state.isMounted) {
              setData(data);
              setTotal(total);
              setLoading(false);
            }
          }
        });
      }, debouncerTimeout);
    },
    [page, pageSize, filter, orderBy, sortingOrder]
  );

  const fetchPages = useCallback(
    async ({ page, pageSize, filter, orderBy, sortingOrder }) => {
      const res = await serviceMethod({
        page,
        limit: pageSize,
        ...filter,
        orderBy,
        sortingOrder
      });
      return res;
    },
    [serviceMethod]
  );

  const refresh = async () => {
    setLoading(true);
    await fetchPages({ page, pageSize, filter, orderBy, sortingOrder }).then((res) => {
      if (res.isError) {
        setData([]);
        setTotal(0);
        toast.error('Nenhum resultado encontrado.', {
          autoClose: false,
          hideProgressBar: true,
          progress: undefined,
          closeButton: true
        });
      } else {
        const { data, total } = res.data;
        setData(data);
        setTotal(total);
        setLoading(false);
      }
    });
  };

  const handlePageChange = useCallback((nextPage) => {
    setPage(nextPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const handleFilterChange = useCallback((newFilter) => {
    setFilter(newFilter);
  }, []);

  const incrementPageTotal = () => {
    setTotal(total + 1);
  };

  const handleSortModelChange = useCallback(
    ([sortingModel]) => {
      setOrderBy(sortingModel?.field || initialOrderBy);
      setSortingOrder(sortingModel?.sort || initialSortingOrder);
    },
    [initialOrderBy, initialSortingOrder]
  );

  return {
    data,
    loading,
    pagination: {
      page,
      pageSize,
      total,
      handlePageChange,
      handlePageSizeChange
    },
    filter: {
      filter,
      handleFilterChange
    },
    sorting: { sortingOrder, orderBy, handleSortModelChange },
    incrementPageTotal,
    refresh
  };
}

import { CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import Modal from '../../components/Modal';
import { useToast } from '../../hooks/useToast';
import { CoursesService } from '../../services/courses';

export function EditModal({ open, close, id, defaultName, updateDataGrid }) {
  const [name, setName] = useState(defaultName);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const updateCourseName = async () => {
    setLoading(true);

    await CoursesService.editCourse({ id, course: name }).then((res) => {
      if (res.status === 201) {
        updateDataGrid([{ id, course: name }]);
        toast.success(`${name} foi editado com sucesso!`);
        close();
        setLoading(false);
      } else {
        setError(
          'Houve um erro na edição desse curso. Verifique se já não existe um curso com o mesmo nome.'
        );
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      open={open}
      close={close}
      closeButtonText="Cancelar"
      submitButtonText={
        <>
          Atualizar{' '}
          {loading && (
            <CircularProgress
              size="0.75em"
              thickness={7}
              disableShrink
              sx={{ ml: 0.75, color: 'inherit' }}
            />
          )}
        </>
      }
      submitButtonAction={updateCourseName}
      title="Nome do curso">
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        label="Nome do curso"
        fullWidth
        error={!!error}
        helperText={error}
      />
    </Modal>
  );
}

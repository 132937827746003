import { useEffect } from 'react';

export function useCleanupEffect(action, dependencies) {
  useEffect(() => {
    const state = { isMounted: true };
    const cleanupAction = action(state);
    return () => {
      state.isMounted = false;
      if (typeof cleanupAction === 'function') {
        cleanupAction();
      }
    };
  }, dependencies);
}

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { LoadingButton } from '@mui/lab';
import { Container, FormHelperText, Stack, Tab, Tabs } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';

import { PartnersService } from '../../services/partners';

import { useToast } from '../../hooks/useToast';
import { isStringAlphaNumeric, validateCnpj } from '../../utils/validate';

import { AccessForm } from './CreateFormComponents/AccessForm';
import { BasicDataForm } from './CreateFormComponents/BasicDataForm';
import { LandingPagesFormForm } from './CreateFormComponents/LandingPagesForm';
import { LocationForm } from './CreateFormComponents/LocationForm';
import { PreCompatibilityForm } from './CreateFormComponents/PreCompatibilityForm';

const schema = yup.object({
  cnpj: yup
    .string()
    .required('O CNPJ é obrigatório.')
    .test({
      test: (s) => validateCnpj(s),
      message: 'Insira um CNPJ válido.'
    }),
  corporateName: yup
    .string()
    .required('A razão social da empresa é obrigatória.')
    .test({
      test: (s) => isStringAlphaNumeric(s),
      message: 'Apenas números, letras, e espaços.'
    })
    .max(32, 'Máximo de 32 caracteres.'),
  commercialName: yup
    .string()
    .required('O nome fantasia da empresa é obrigatória.')
    .test({
      test: (s) => isStringAlphaNumeric(s),
      message: 'Apenas números, letras, e espaços.'
    })
    .max(32, 'Máximo de 32 caracteres.'),
  companySize: yup.string().required('O tamanho da empresa é obrigatório.'),
  industries: yup
    .array()
    .of(
      yup.object({
        label: yup.string(),
        value: yup.string()
      })
    )
    .min(1, 'Escolha pelo menos um setor.'),
  email: yup
    .string()
    .email('Por favor insira um e-mail válido.')
    .required('O e-mail é obrigatório.'),
  phone: yup.string().length(11, 'O número de telefone deve ter um DDD + 9 dígitos.'),
  ddi: yup.string().required('O código do país é obrigatório.'),
  defaultLocation: yup.object({
    name: yup.string().required('O nome da localização é obrigatório.'),
    cep: yup.string().required('O CEP é obrigatório.'),
    neighborhood: yup.string().required('O bairro é obrigatório.'),
    address: yup.string().required('O endereço é obrigatório.'),
    number: yup
      .number('Insira um número.')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('O número é obrigatório.'),
    complement: yup.string(),
    city: yup.string().required('Insira um CEP.'),
    state: yup.string().required('Insira um CEP.')
  }),
  accesses: yup.array().of(
    yup.object({
      email: yup
        .string()
        .email('Por favor insira um e-mail válido.')
        .required('O e-mail do usuário é obrigatório.'),
      permission: yup.string().required('O tipo de acesso é obrigatório.')
    })
  )
});

export default function CreatePartners() {
  const methods = useForm({
    defaultValues: {
      cnpj: '',
      corporateName: '',
      commercialName: '',
      companySize: '',
      industries: [],
      email: '',
      phone: '',
      ddi: '0055',
      defaultLocation: {
        name: '',
        cep: '',
        neighborhood: '',
        address: '',
        number: '',
        complement: '',
        city: '',
        state: '',
        country: 'Brasil'
      },
      accesses: [
        {
          email: '',
          permission: ''
        }
      ],
      afterSubmit: ''
    },
    resolver: yupResolver(schema)
  });

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
    reset
  } = methods;

  const [tab, setTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditing = !!searchParams.get('company');
  const [companyId, setCompanyId] = useState(searchParams.get('company') || '');
  const [isTabDisabled, setIsTabDisabled] = useState([false, !isEditing, !isEditing]);

  const toast = useToast();

  const submit = async (data) => {
    try {
      const { phone, ddi, industries, afterSubmit, cnpj, defaultLocation, ...rest } = data;

      const phoneNumber = `+${ddi}${phone}`;

      const payload = {
        ...rest,
        phoneNumber,
        cnpj,
        defaultLocation: {
          ...defaultLocation,
          ...(!defaultLocation.complement
            ? { complement: undefined }
            : { complement: defaultLocation.complement })
        },
        industries: industries.map(({ value }) => value)
      };

      const action = async () => {
        if (!searchParams.get('company')) {
          return PartnersService.createNewPartner(payload);
        }
        return PartnersService.updatePartnerData(searchParams.get('company'), payload);
      };

      const res = await action();
      if (res.isError) {
        if (res.status === 409) {
          if (res.data.message.includes('cnpj')) {
            setError('cnpj', {
              message: 'Já existe uma empresa com este CNPJ na plataforma.'
            });
            throw new Error('CNPJ já existe na plataforma.');
          } else if (res.data.message.includes('corporate')) {
            setError('corporateName', {
              message: 'Já existe uma empresa com esta razão social na plataforma.'
            });
            throw new Error('Razão social já existe na plataforma.');
          }
        }
        throw new Error();
      }

      setSearchParams({ company: res.data.data._id });
      setTab(1);
      setIsTabDisabled([false, false, false]);
      setCompanyId(res.data.data._id);
    } catch (err) {
      setError('afterSubmit', {
        message: err?.message || 'Houve um erro na criação da agência. Por favor tente novamente.'
      });
    }
  };

  useEffect(() => {
    const company = searchParams.get('company') || '';

    if (company) {
      setCompanyId(company);
      try {
        PartnersService.getPartnerData(company).then(async (res) => {
          if (res.isError) throw new Error();

          const { data } = res.data;
          const { phoneNumber, cnpj, industries, ...rest } = data;

          reset({
            ...rest,
            cnpj,
            industries: industries.map((v) => ({ label: v, value: v })),
            phone: phoneNumber.slice(5),
            ddi: phoneNumber.slice(1, 5)
          });

          setIsTabDisabled([false, false, false]);
        });
      } catch {
        toast.error('Houve um erro ao buscar os dados da empresa.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Page title={`${isEditing ? 'Editar agência' : 'Criar agência'} | Preparo`}>
      <Container>
        <HeaderBreadcrumbs
          heading={isEditing ? 'Editar agência' : 'Criar agência'}
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'Agências', href: '/dashboard/agencies' },
            { name: isEditing ? 'Editar agência' : 'Criar agência' }
          ]}
        />

        <Tabs
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
          selectionFollowsFocus
          sx={{ mb: 2 }}>
          <Tab label="Dados gerais" icon={<AccountBoxRoundedIcon />} iconPosition="start" />
          <Tab
            label="Pré-compatibilidade"
            disabled={isTabDisabled[1]}
            icon={<PeopleAltRoundedIcon />}
            iconPosition="start"
          />
          <Tab
            label="Landing Pages"
            disabled={isTabDisabled[2]}
            icon={<HomeRoundedIcon />}
            iconPosition="start"
          />
        </Tabs>

        <Stack gap={3}>
          {tab === 0 && (
            <>
              <BasicDataForm methods={methods} />
              <LocationForm methods={methods} />
              <AccessForm methods={methods} />
              {errors?.afterSubmit?.message && (
                <FormHelperText error>{errors.afterSubmit.message}</FormHelperText>
              )}
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit(submit, (err) => console.log(err))}
                variant="contained"
                size="large"
                sx={{ alignSelf: 'flex-end', textTransform: 'none' }}>
                {isEditing ? 'Editar agência e avançar' : 'Criar agência e avançar'}
              </LoadingButton>
            </>
          )}

          {tab === 1 && (
            <PreCompatibilityForm
              companyId={companyId}
              getNextStep={() => setTab((prev) => prev + 1)}
            />
          )}

          {tab === 2 && <LandingPagesFormForm companyId={companyId} />}
        </Stack>
      </Container>
    </Page>
  );
}

import { api } from '../index';
import { USER } from '../routes';

export class UsersService {
  /**
   * @param {{ page:number, limit:number, orderBy: string, ascending: boolean }} params
   * @returns
   */
  static async getPaginated(params) {
    return api.get(USER.PAGINATED, {
      params
    });
  }

  static async getCsv(params) {
    return api.get(USER.CSV, {
      params
    });
  }
}

import { useCallback, useRef, useState } from 'react';
import { HigherEducationsService } from '../services/HigherEducations';
import { useCleanupEffect } from './useCleanupEffect';
import { useToast } from './useToast';

/**
 *
 * @param {{serviceMethod: () => Promise<{data}>, initialValues: { page: number, pageSize: number, sortingOrder: 'asc' | 'desc', orderBy: 'createdAt' | string }}} param0
 * @returns
 */
export function useInstituitions({
  serviceMethod,
  initialValues = {
    page: 0,
    orderBy: 'createdAt',
    pageSize: 15,
    sortingOrder: 'desc'
  },
  debouncerTimeout = 300
}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(initialValues?.page);
  const [pageSize, setPageSize] = useState(initialValues?.pageSize);
  const [total, setTotal] = useState(0);
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState(initialValues?.orderBy);
  const [sortingOrder, setSortingOrder] = useState(initialValues?.sortingOrder);
  const [filter, setFilter] = useState({});
  const timeout = useRef(null);

  useCleanupEffect(
    (state) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setLoading(true);
        fetchPages({ pageSize, page, orderBy, sortingOrder, filter }).then((res) => {
          const { data } = res.data;
          if (res.isError) {
            setData([]);
            setTotal(0);
            return toast.error('Nenhum resultado encontrado.', {
              autoClose: false,
              hideProgressBar: true,
              progress: undefined,
              closeButton: true
            });
          }
          if (state.isMounted) {
            setData(data);
            setLoading(false);
          }
          return () => {};
        });
      }, debouncerTimeout);
    },
    [page, pageSize, filter, orderBy, sortingOrder]
  );

  useCleanupEffect(
    (state) => {
      HigherEducationsService.getTotalUniversities(filter).then((res) => {
        if (state.isMounted) {
          setTotal(res.data.data.totalNumber);
        }
      });
    },
    [filter]
  );

  const addTotalNumber = () => {
    setTotal(total + 1);
  };

  const fetchPages = useCallback(
    async ({ pageSize, page, orderBy, sortingOrder, filter }) => {
      const response = await serviceMethod({
        limit: pageSize,
        page,
        orderBy,
        sortingOrder,
        ...filter
      });
      return response;
    },
    [serviceMethod]
  );

  const refresh = async () => {
    setLoading(true);
    const res = await fetchPages({ page, pageSize, filter, orderBy, sortingOrder });
    setLoading(false);
    return res;
  };

  function updateRows(activeSelectedRow) {
    setData(
      data.map((res) => {
        if (activeSelectedRow.includes(res.id)) {
          return { ...res, status: !res.status };
        }
        return res;
      })
    );
  }

  const handleFilterChange = useCallback((newFilter) => {
    setFilter(newFilter);
  }, []);

  const handlePageChange = useCallback((nextPage) => {
    setPage(nextPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, []);

  const handleSortModelChange = useCallback(([sortingModel]) => {
    setOrderBy(sortingModel?.field);
    setSortingOrder(sortingModel?.sort);
  }, []);

  return {
    data,
    loading,
    filter: {
      handleFilterChange
    },
    pagination: { page, pageSize, addTotalNumber, total, handlePageChange, handlePageSizeChange },
    sorting: { sortingOrder, orderBy, handleSortModelChange },
    updateRows,
    refresh
  };
}

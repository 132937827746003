import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from 'date-fns/locale';

export function DateInput({ label, value, onChange, ...rest }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <DatePicker
        label={label}
        renderInput={(params) => <TextField {...params} />}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export function profilePictureURL(user) {
  return `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=637381&color=fff`;
}

/**
 *
 * @param {number} number
 * @returns string
 */
export function formatCurrency(number) {
  return number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
}

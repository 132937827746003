import { useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Card, Container } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import DomainDisabledRoundedIcon from '@mui/icons-material/DomainDisabledRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ToggleOffRoundedIcon from '@mui/icons-material/ToggleOffRounded';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';

import Label from '../../components/Label';

import { PartnersService } from '../../services/partners';

import { useFilteredPagination } from '../../hooks/useFilteredPagination';
import { useToast } from '../../hooks/useToast';

export default function Partners() {
  const { data, loading, pagination, sorting, refresh } = useFilteredPagination({
    serviceMethod: (params) => PartnersService.getSubCompanies({ ...params, isAgency: true }),
    initialSortingOrder: 'desc',
    initialOrderBy: 'createdAt',
    initialPageSize: 10
  });

  const columns = useMemo(
    () => [
      {
        field: 'commercialName',
        headerName: 'Nome fantasia',
        flex: 1,
        minWidth: 250,
        sortable: true
      },
      {
        field: 'active',
        headerName: 'Status',
        flex: 0.5,
        minWidth: 75,
        sortable: false,
        renderCell: (params) =>
          params.row.active ? (
            <DomainRoundedIcon sx={{ color: 'info.dark' }} />
          ) : (
            <DomainDisabledRoundedIcon sx={{ color: 'error.dark' }} />
          ),
        align: 'center'
      },
      {
        field: 'industries',
        headerName: 'Setores da empresa',
        flex: 1.5,
        minWidth: 200,
        sortable: false,
        renderCell: (params) =>
          params.row?.industries.map((ind) => (
            <Label key={ind} sx={{ mr: 1 }}>
              {ind}
            </Label>
          ))
      },
      {
        field: 'cnpj',
        headerName: 'CNPJ',
        flex: 1.5,
        minWidth: 200,
        sortable: false,
        valueFormatter: (params) =>
          params.value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      },
      {
        field: 'email',
        headerName: 'E-mail',
        sortable: false,
        flex: 2,
        minWidth: 250
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Opções',
        sortable: false,
        minWidth: 50,
        flex: 0.5,
        align: 'right',
        getActions: (params) => Menu({ _id: params.row._id, active: params.row.active, refresh })
      }
    ],
    [refresh]
  );

  return (
    <Page title="Agências | Preparo">
      <Container>
        <HeaderBreadcrumbs
          heading="Agências"
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'Agências', href: '/partners' }
          ]}
        />

        <Card>
          <DataGrid
            rows={data}
            loading={loading}
            columns={columns}
            getRowId={(row) => `${row._id}-rows`}
            rowsPerPageOptions={[10, 20, 30]}
            disableSelectionOnClick
            disableColumnMenu
            autoHeight
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            page={pagination.page}
            pageSize={pagination.pageSize}
            onPageSizeChange={pagination.handlePageSizeChange}
            onPageChange={pagination.handlePageChange}
            rowCount={pagination.total}
            sortModel={[{ field: sorting.orderBy, sort: sorting.sortingOrder }]}
            onSortModelChange={sorting.handleSortModelChange}
            sx={{
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { px: 1, py: 2 },
              '& .MuiDataGrid-row:hover': { backgroundColor: 'primary.5008' },
              '.MuiDataGrid-columnSeparator': { display: 'none' },
              '&.MuiDataGrid-root': { border: 'none' },
              '.MuiDataGrid-renderingZone, .MuiDataGrid-root .MuiDataGrid-row': {
                width: '100% !important'
              }
            }}
          />
        </Card>
      </Container>
    </Page>
  );
}

function Menu({ _id, active, refresh }) {
  const navigate = useNavigate();
  const toast = useToast();

  const action = async () => {
    try {
      if (active) {
        await PartnersService.deactivateCompany(_id).then((res) => {
          if (res.isError) throw new Error();
        });
      } else {
        await PartnersService.activateCompany(_id).then((res) => {
          if (res.isError) throw new Error();
        });
      }

      const msg = `Empresa ${active ? 'des' : ''}ativada com sucesso.`;

      await refresh();

      toast.info(msg);
    } catch {
      toast.error('Houve um erro ao realizar a ação. Por favor tente novamente.');
    }
  };

  return [
    <GridActionsCellItem
      key={0}
      showInMenu
      onClick={() =>
        navigate({
          pathname: `/dashboard/agencies/create`,
          search: `?${createSearchParams({ company: _id })}`
        })
      }
      label="Editar empresa"
      disabled={active}
      icon={<EditRoundedIcon />}
      sx={{ fontSize: '0.75rem' }}
    />,
    <GridActionsCellItem
      key={1}
      showInMenu
      label={active ? 'Desativar empresa' : 'Ativar empresa'}
      onClick={action}
      icon={active ? <ToggleOffRoundedIcon /> : <ToggleOnRoundedIcon />}
      sx={{ fontSize: '0.75rem', color: active ? 'error.main' : 'info.main' }}
    />
  ];
}

import { Card, Container, Stack, Typography } from '@mui/material';
import { DataGridCustom } from '../components/DataGridCustom';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { usePagination } from '../hooks/usePagination';
import { CompanyService } from '../services/companies';

const initialValues = { page: 0, pageSize: 15, sortingOrder: 'desc', orderBy: 'createdAt' };

export default function Company() {
  const { data, loading, pagination, sorting } = usePagination({
    serviceMethod: CompanyService.getPaginated,
    initialValues
  });

  return (
    <Page title="Staff | Preparo">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Empresas
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <DataGridCustom
              data={data}
              columns={columns}
              pagination={pagination}
              sorting={sorting}
              loading={loading}
            />
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

const columns = [
  { field: '_id', headerName: 'ID', width: 200, hide: true },
  { field: 'companyName', headerName: 'Nome', width: 200 },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
    renderCell: ({ value }) => <Typography title={value}>{value}</Typography>
  },
  { field: 'financialEmail', headerName: 'Email Financeiro', width: 200 },
  { field: 'cnpj', headerName: 'CNPJ', width: 200 },
  { field: 'phoneNumber', headerName: 'Telefone', width: 200 },
  { field: 'companySector', headerName: 'Setor', width: 200 },
  { field: 'companyType', headerName: 'Tipo', width: 200 },
  {
    field: 'createdAt',
    headerName: 'Data de Criação',
    width: 200,
    renderCell: ({ value }) => {
      const date = new Date(value);
      return <Typography title={date}>{date.toLocaleString()}</Typography>;
    }
  }
];

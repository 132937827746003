import faker from 'faker';
import { mockImgCover } from '../utils/mockImages';

const POST_TITLES = [
  'Principais oportunidades da semana',
  'Reage, universitário',
  'Passo a passo para definir a sua marca pessoal no ambiente de trabalho',
  '5 curiosidades sobre estágio no Brasil',
  'Quais são os seus pontos fortes e fracos?',
  'Por que devemos contratá-lo?',
  '5 cursos gratuitos para aprender a programar',
  'Principais oportunidades da semana',
  'A habilidade que todo profissional deve ter',
  'Retrospectiva 2021',
  'Principais oportunidades da semana',
  '3 perguntas comuns em entrevistas e como respondê-las',
  'Como traçar metas efetivas para o ano',
  'Principais oportunidades da semana',
  'A anatomia de um currículo perfeito',
  'Principais oportunidades da semana',
  'Conselhos de Steve Jobs para aplicar na sua carreira',
  'Qual é o seu nível de Excel?',
  'Principais oportunidades da semana',
  'O que Harvard diz sobre montar um currículo'
];

const posts = [...Array(23)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: mockImgCover(index + 1),
  title: POST_TITLES[index + 1],
  createdAt: faker.date.past(),
  view: faker.datatype.number(),
  comment: faker.datatype.number(),
  share: faker.datatype.number(),
  favorite: faker.datatype.number(),
  author: {
    name: faker.name.findName(),
    avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`
  }
}));

export default posts;

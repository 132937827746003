import { memo } from 'react';
import styles from './loading.module.css';

export function Loading() {
  return (
    <div className={styles.wrapper}>
      <PreparoAnimated className={styles.svg} />
    </div>
  );
}

const PreparoAnimated = memo((props) => (
  <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svg}>
    <path
      d="M19.374 24.667H9.627C4.319 24.667 0 20.309 0 14.953V9.71C.004 4.358 4.32 0 9.627 0h9.747C24.682 0 29 4.358 29 9.71v5.238c0 5.36-4.319 9.719-9.626 9.719zM9.627 6.69c-1.655 0-2.997 1.355-2.997 3.024v5.239c0 1.669 1.342 3.024 2.997 3.024h9.747c1.654 0 2.997-1.355 2.997-3.024V9.71c0-1.67-1.343-3.024-2.997-3.024H9.627v.004z"
      fill="#3B4B5B"
    />
    <path
      className={styles.circle}
      d="M8.156 32.73c0-2.36-1.828-4.269-4.078-4.269-2.254 0-4.078 1.914-4.078 4.27C0 35.09 1.828 37 4.078 37c2.254 0 4.078-1.91 4.078-4.27z"
      fill="#C93B59"
    />
  </svg>
));

PreparoAnimated.displayName = 'PreparoAnimated';

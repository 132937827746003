import { api } from './index';

export class PartnersService {
  static async getCompanyIndustries() {
    return api.get('/admin/v2/companies/industries');
  }

  static async createNewPartner(payload) {
    return api.post('/admin/v2/companies/agency', { payload });
  }

  static async getSubCompanies({
    commercialName,
    isAgency,
    active,
    page = 0,
    limit = 5,
    orderBy = 'createdAt',
    sortingOrder = '-1',
    ...params
  }) {
    return api.get('/admin/v2/companies/', {
      params: {
        commercialName,
        isAgency,
        active,
        page,
        limit,
        orderBy,
        sortingOrder: sortingOrder === 'asc' || sortingOrder === '1' ? '1' : '-1',
        ...params
      }
    });
  }

  static async getPartnerData(companyId) {
    return api.get(`/admin/v2/companies/${companyId}/agency`);
  }

  static async updatePartnerData(companyId, payload) {
    return api.patch(`/admin/v2/companies/${companyId}/agency`, payload);
  }

  static async activateCompany(_id) {
    return api.patch(`/admin/v2/companies/${_id}/activate`);
  }

  static async deactivateCompany(_id) {
    return api.patch(`/admin/v2/companies/${_id}/deactivate`);
  }

  static async getCompatibilityCount(params) {
    return api.get(`/admin/v2/companies/agency/count-pre-compatibility`, { params });
  }

  static async getPreCompatibility(companyId) {
    return api.get(`/admin/v2/companies/${companyId}/agency/pre-compatibility`);
  }

  static async setPreCompatibility(companyId, payload) {
    return api.put(`/admin/v2/companies/${companyId}/agency/pre-compatibility`, { payload });
  }

  static async uploadLandingPageData(companyId, payload) {
    return api.post(`/admin/v2/companies/${companyId}/landing-page`, { payload });
  }

  static async setLandingPageLogo(companyId, file) {
    return api.patch(`/admin/v2/companies/${companyId}/landing-page/logo`, file);
  }

  static async setLandingPagePhoto(companyId, perspective, file) {
    return api.patch(
      `/admin/v2/companies/${companyId}/landing-page/photo?perspective=${perspective}`,
      file
    );
  }

  static async editLandingPageData(companyId, payload) {
    return api.patch(`/admin/v2/companies/${companyId}/landing-page`, { payload });
  }

  static async getLandingPageData({ subdomain, companyId, perspective }) {
    return api.get(`/public/v2/companies/landing-page`, {
      params: { perspective, companyId, subdomain }
    });
  }
}

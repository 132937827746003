import { api } from '../index';

export class CoursesService {
  static async getCourses({ page, limit, status, analyzed, course, orderBy, sortingOrder }) {
    return api.get(`/courses`, {
      params: { page, limit, status, analyzed, course, orderBy, sortingOrder }
    });
  }

  static async registerNewCourse(course) {
    return api.post('/courses', {
      payload: { course }
    });
  }

  static async getCourseById(id) {
    return api.get(`/courses/${id}`);
  }

  static async getInstitutionById(id) {
    return api.get(`/institutions/${id}`);
  }

  static async getTotalNumberOfCourses({ status, analyzed, course }) {
    return api.get('/counts/courses', {
      params: { status, analyzed, course }
    });
  }

  static async editCourse({ id, course, status, analyzed }) {
    return api.put(`/courses/${id}`, {
      payload: {
        course,
        status,
        analyzed
      }
    });
  }

  static async replaceCourse({ courseToBeDeletedId, courseToReplaceId }) {
    return api.put(`/courses/${courseToBeDeletedId}/replace/${courseToReplaceId}`);
  }

  static async updateMany({ ids, status }) {
    return api.put('/courses/update-many/status', {
      payload: {
        ids,
        status
      }
    });
  }
}

import { useEffect, useRef, useState } from 'react';

export function useGetRequest({
  serviceMethod,
  defaultValue = null,
  args = undefined,
  transformFunction = (res) => res
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(defaultValue);
  const state = useRef({ isMounted: true });

  useEffect(() => {
    serviceMethod(args).then((res) => {
      if (state.current.isMounted) {
        setResponse(transformFunction(res.data));
        setIsLoading(false);
      }
    });
    return () => {
      state.current.isMounted = false;
    };
  }, []);

  async function refresh() {
    setIsLoading(true);
    const res = await serviceMethod(args);
    setResponse(transformFunction(res.data));
    setIsLoading(false);
  }

  return [response, isLoading, refresh];
}

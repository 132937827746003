export const AUTH = {
  CHECK_AUTHENTICATION: '/admin/check-authentication',
  AUTHENTICATE: '/admin/authenticate',
  LOGOUT: '/logout'
};

export const USER = {
  PAGINATED: '/admin/user',
  CSV: '/admin/user/csv'
};

export const COMPANY = {
  PAGINATED: '/admin/company'
};

export const PROCESS = {
  PAGINATED: '/admin/process'
};

export const STATS = {
  GET_STATS: '/admin/get-stats'
};

export const MANAGEMENT = {
  LIST_STAFF: '/admin/staff', // get
  ADD_MEMBER: '/admin/staff', // post
  DEACTIVATE_MEMBER: '/admin/staff/deactivate', // patch
  ACTIVATE_MEMBER: '/admin/staff/activate', // patch
  UPDATE_AUTHORITY: '/admin/staff/update-authority' // patch
};

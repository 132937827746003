import { useState } from 'react';

export function useObjectState(object = {}) {
  const [value, setValue] = useState(() => object || {});

  function updateValue(key, value) {
    setValue((prev) => ({ ...prev, [key]: value }));
  }

  return [value, updateValue];
}

import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo } from 'react';
import { usePersistedState } from '../hooks/usePersistedState';
import componentsOverride from './overrides';
import { dark, light } from './palette';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export default function ThemeConfig({ children }) {
  const [themeMode, setThemeMode] = usePersistedState('theme@preparo', 'light');

  const toggleColorMode = useCallback(() => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
  }, [themeMode]);

  const palette = useMemo(() => (themeMode === 'light' ? light : dark), [themeMode]);

  const themeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows: shadows(palette),
      customShadows: customShadows(palette)
    }),
    [themeMode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  const value = useMemo(() => ({ toggleColorMode }), []);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={value}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StyledEngineProvider>
  );
}

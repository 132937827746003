import { Box, Card, CircularProgress, InputAdornment, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { useWatch } from 'react-hook-form';
import {
  RHFNumberTextField,
  RHFPatternTextField,
  RHFTextField
} from '../../../components/hook-form';

import { searchCep } from '../../../utils/searchCep';

export function LocationForm({ methods }) {
  const {
    setValue,
    setError,
    getValues,
    clearErrors,
    formState: { isSubmitting },
    control
  } = methods;

  const [loadingCep, setLoadingCep] = useState(false);

  const [city, state, address, neighborhood] = useWatch({
    name: [
      'defaultLocation.city',
      'defaultLocation.state',
      'defaultLocation.address',
      'defaultLocation.neighborhood'
    ],
    control
  });

  const setValuesAfterCep = async () => {
    const cep = getValues('defaultLocation.cep');

    if (!cep) return;

    setLoadingCep(true);
    await searchCep(cep)
      .then((res) => {
        if (res.cep) {
          setValue('defaultLocation.city', res.localidade);
          setValue('defaultLocation.state', res.uf);

          setValue('defaultLocation.address', res.logradouro);
          setValue('defaultLocation.neighborhood', res.bairro);

          setValue('defaultLocation.complement', '');
          setValue('defaultLocation.number', '');

          setValue('defaultLocation.country', 'Brasil');

          clearErrors('defaultLocation.cep');
        } else {
          setError('defaultLocation.cep', {
            message: 'CEP inválido.'
          });
        }
      })
      .catch(() => {
        setError('defaultLocation.cep', {
          message: 'CEP inválido.'
        });
      });
    setLoadingCep(false);
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack gap={2}>
        <Typography variant="h6">Localização</Typography>

        <RHFTextField
          control={control}
          disabled={isSubmitting}
          name="defaultLocation.name"
          label="Nome"
        />

        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
          }}>
          <RHFPatternTextField
            control={control}
            format="#####-###"
            mask="_"
            valueIsNumericString
            name="defaultLocation.cep"
            onBlur={setValuesAfterCep}
            label="Código postal"
            disabled={isSubmitting}
            InputProps={{
              endAdornment: loadingCep && (
                <InputAdornment position="end">
                  <CircularProgress color="primary" disableShrink thickness={6} size={20} />
                </InputAdornment>
              )
            }}
          />

          <RHFTextField
            control={control}
            name="defaultLocation.neighborhood"
            disabled={isSubmitting}
            label="Bairro"
            InputLabelProps={{
              shrink: !!neighborhood
            }}
          />

          <RHFTextField
            control={control}
            name="defaultLocation.address"
            disabled={isSubmitting}
            label="Endereço"
            InputLabelProps={{
              shrink: !!address
            }}
          />
          <RHFNumberTextField
            control={control}
            name="defaultLocation.number"
            disabled={isSubmitting}
            label="Número"
            decimalScale={0}
            valueIsNumericString
          />
          <RHFTextField
            control={control}
            name="defaultLocation.complement"
            label="Complemento"
            disabled={isSubmitting}
          />
          <RHFTextField
            control={control}
            InputLabelProps={{
              shrink: !!city
            }}
            name="defaultLocation.city"
            disabled
            label="Cidade"
          />

          <RHFTextField
            control={control}
            InputLabelProps={{
              shrink: !!state
            }}
            name="defaultLocation.state"
            disabled
            label="Estado/região"
          />

          <RHFTextField control={control} name="defaultLocation.country" disabled label="País" />
        </Box>
      </Stack>
    </Card>
  );
}
